import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import DOMPurify from 'dompurify';
import { useSnackbar } from 'notistack';
import {
    Box,
    Button,
    Typography,
    Grid,
    Avatar,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    IconButton,
    TextField,
    InputAdornment,
    FormControl,
    Tooltip,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    FormControlLabel,
    Switch,
    Link,
    Badge,
} from '@mui/material';

import {
    PlaylistAddRounded,
    SearchOutlined,
    ModeEdit as ModeEditIcon,
    Delete as DeleteIcon,
    Lock as LockIcon,
    Close as CloseIcon,
    Visibility as VisibilityIcon,
    VisibilityOff as VisibilityOffIcon,
    Cancel as CancelIcon,
    FiberManualRecord as FiberManualRecordIcon,
    RemoveRedEye as RemoveRedEyeIcon,
    RestartAlt as RestartAltIcon,
    Favorite as FavoriteIcon,
    PersonPin as PersonPinIcon,
    SearchOutlined as SearchOutlinedIcon,
    Menu as MenuIcon,
    MoreVert as MoreVertIcon,
} from '@mui/icons-material';

import MiniDrawer from '../../../components/drawer';

const Users = () => {
    let navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [page, setPage] = useState(0);
    const [total, setTotal] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [users, setUsers] = useState([]);
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('');
    const [openView, setOpenView] = useState(false);
    const [alldata, setAlldata] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [passId, setPassId] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passError, setPassError] = useState('');
    const [conpassError, setConpassError] = useState('');

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const getUsers = async () => {
        try {
            let roleusers = true;
            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}adminpart/getAdminusers?page=${page}&size=${rowsPerPage}&search=${search}&roleusers=${roleusers}`
            );
            console.log(response.data.data, "response.data.data");
            setUsers(response.data.data);
            setTotal(response.data.totalcount);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(0);
    };

    const handleClickOpen = (data) => {
        setDeleteId(data._id);
        setOpen(true);
    };

    const handleClose = () => {
        setDeleteId('');
        setOpen(false);
    };

    const handleView = (data) => {
        setAlldata(data);
        setOpenView(true);
    };

    const handleResetPass = (row) => {
        setPassId(row);
        setModalOpen(true);
    };

    const handleCloseModal = () => {
        setModalOpen(false);
        setNewPassword('');
        setConfirmPassword('');
        setPassError('');
        setConpassError('');
    };

    const handleToggleNewPasswordVisibility = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleToggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const validation = () => {
        if (!newPassword && !confirmPassword) {
            setPassError('This field is required');
            setConpassError('This field is required');
            return false;
        } else if (!newPassword) {
            setPassError('This field is required');
            return false;
        } else if (!confirmPassword) {
            setConpassError('This field is required');
            return false;
        } else if (newPassword !== confirmPassword) {
            setConpassError('Password Mismatching');
            return false;
        }
        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let valid = validation();
        if (valid) {
            let body = {
                confirmPassword: confirmPassword,
                newPassword: newPassword,
                email: passId.email,
                id: passId._id,
            };
            try {
                await axios.post(`${process.env.REACT_APP_BASE_URL}talents/userresetpassword`, body);
                navigate('user');
                enqueueSnackbar('Password reset successfully', { variant: 'success' });
            } catch (err) {
                navigate('user');
                enqueueSnackbar('Something went wrong', { variant: 'error' });
                console.error('Error:', err);
            }
        }
    };

    useEffect(() => {
        getUsers();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage, page, search]);

    const sanitizedContent = DOMPurify.sanitize(alldata.Description, { ALLOWED_TAGS: ['img'] });

    const DeletUser = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_BASE_URL}talents/deletetheadminusers/${deleteId}`);
            enqueueSnackbar('Deleted successfully', { variant: 'success' });
            getUsers();
            handleClose();
        } catch (error) {
            console.error('Error deleting user:', error);
        }
    };

    const handleRemove = () => {
        setSearch('');
    };

    const AddUser = () => {
        navigate('/adduser');
    };

    const EditData = (row) => {
        navigate('/edituser', { state: row });
    };

    const DeletData = (row) => {
        setDeleteId(row._id);
        setOpen(true);
    };

    return (
        <Box className="companies">
            <MiniDrawer type={11} />
            <Box component="section" className="contentWraper">
                <Box className="headingBox">
                    <Typography component="h4" variant="h4" className='page-heading' >
                        Users
                    </Typography>
                    <Button variant="contained" className="addbtn" onClick={AddUser} style={{ marginTop: '20px' }}>
                        <PlaylistAddRounded className="fz-18 mr-5" /> Add User
                    </Button>
                </Box>
                <Grid container rowSpacing={2} columnSpacing={2} style={{ marginBottom: 10 }} className="align-items-end">
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl variant="outlined" size="small">
                            <TextField
                                id="filled-size-small"
                                variant="outlined"
                                size="small"
                                value={search}
                                onChange={handleSearchChange}
                                placeholder="Search Here..."
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchOutlinedIcon className="fz-18" />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            {search && (
                                                <IconButton onClick={handleRemove}>
                                                    <CancelIcon className="fz-18" />
                                                </IconButton>
                                            )}
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Box className="whiteContainer">
                    <TableContainer component={Paper} id="table">
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" className="">
                            <TableHead className="DT-head">
                                <TableRow className="">
                                    <TableCell align="left" className="TRow DT-checkbox" style={{ minWidth: 20, maxWidth: 20, padding: '6px 16px', fontWeight:'bold' }}>
                                        Image
                                    </TableCell>
                                    <TableCell align="left" className="TRow DT-checkbox" style={{ minWidth: 20, maxWidth: 20, padding: '6px 16px', fontWeight:'bold' }}>
                                        Username
                                    </TableCell>

                                    <TableCell align="left" className="TRow DT-checkbox" style={{ minWidth: 20, maxWidth: 100, padding: '6px 16px', fontWeight:'bold' }}>
                                        Role
                                    </TableCell>
                                    <TableCell align="left" className="TRow DT-checkbox" style={{ minWidth: 20, maxWidth: 20, padding: '6px 16px', fontWeight:'bold' }}>
                                        Email
                                    </TableCell>
                                    <TableCell align="left" className="TRow DT-checkbox" style={{ minWidth: 20, maxWidth: 20, padding: '6px 16px', fontWeight:'bold' }}>
                                        Phone
                                    </TableCell>
                                    <TableCell align="left" className="TRow DT-checkbox" style={{ minWidth: 20, maxWidth: 20, padding: '6px 16px', fontWeight:'bold' }}>
                                        Action
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className="DT-body">
                                {users.map((row, index) => (
                                    <TableRow key={row._id}>
                                        <TableCell align="left" style={{ minWidth: 20, maxWidth: 20, }}>
                                            <Avatar alt={row.username} src={row.image} />
                                        </TableCell>
                                        <TableCell align="left" className="user">{row.name}</TableCell>
                                        <TableCell align="left" className="user">{row.role_name ? row.role_name.role_title : '-'}</TableCell>
                                        <TableCell align="left" className="user">{row.email}</TableCell>
                                        <TableCell align="left" className="user">{row.phone}</TableCell>
                                        <TableCell align="left" style={{ minWidth: 70, maxWidth: 100, padding: '6px 16px' }}>
                                            {/* <Tooltip title="View"><IconButton size="small" onClick={() => handleView(row)}><RemoveRedEyeIcon /></IconButton></Tooltip> */}
                                            <Tooltip title="Edit"><IconButton size="small" onClick={() => EditData(row)}><ModeEditIcon /></IconButton></Tooltip>
                                            <Tooltip title="Delete" ><IconButton size="small" onClick={() => DeletData(row)}><DeleteIcon /></IconButton></Tooltip>
                                            <Tooltip title="Reset Password" ><IconButton size="small" onClick={() => handleResetPass(row)}><LockIcon /></IconButton></Tooltip>
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
                <Dialog open={open} onClose={handleClose}>
                    <DialogTitle>{`Are you sure you want to delete this user?`}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            By deleting this user, you will lose access to their account. This action cannot be undone.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={DeletUser} color="primary">
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog fullWidth maxWidth="sm" open={openView} onClose={() => setOpenView(false)}>
                    <DialogTitle>{`User Information`}</DialogTitle>
                    <DialogContent>
                        <Box display="flex" alignItems="center" flexDirection="column">
                            <Box className="data">
                                <Typography variant="h6" component="p">
                                    Username: {alldata.username}
                                </Typography>
                                <Typography variant="h6" component="p">
                                    Position: {alldata.Position}
                                </Typography>
                                <Typography variant="h6" component="p">
                                    Role: {alldata.role}
                                </Typography>
                                <Typography variant="h6" component="p">
                                    Phone: {alldata.Phone}
                                </Typography>
                                <Typography variant="h6" component="p">
                                    Email: {alldata.email}
                                </Typography>
                                <Typography variant="h6" component="p">
                                    Bio: <span dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                                </Typography>
                            </Box>
                            <Box className="fz-18 mr-5">
                                <IconButton
                                    size="small"
                                    onClick={() => setOpenView(false)}
                                    style={{ marginLeft: 'auto' }}
                                    color="inherit"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
                <Dialog open={modalOpen} onClose={handleCloseModal} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Reset Password</DialogTitle>
                    <DialogContent>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="password"
                            label="New Password"
                            type={showNewPassword ? 'text' : 'password'}
                            fullWidth
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                            error={!!passError}
                            helperText={passError}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggleNewPasswordVisibility} edge="end">
                                            {showNewPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            autoFocus
                            margin="dense"
                            id="password"
                            label="Confirm Password"
                            type={showConfirmPassword ? 'text' : 'password'}
                            fullWidth
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            error={!!conpassError}
                            helperText={conpassError}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton onClick={handleToggleConfirmPasswordVisibility} edge="end">
                                            {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseModal} className="addbtn" style={{ color: '#B4522D' }}>
                            Cancel
                        </Button>
                        <Button onClick={handleSubmit} className="addbtn" style={{ color: '#B4522D' }}>
                            Reset
                        </Button>

                    </DialogActions>
                </Dialog>
            </Box>
        </Box>
    );
};

export default Users;
