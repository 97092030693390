import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Checkbox, Grid, InputAdornment, TextField, FormControlLabel, Typography } from '@mui/material'
import axios from 'axios';
import { useSnackbar } from 'notistack'
import { useNavigate, useLocation } from 'react-router-dom'


export default function Addrole() {

    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const auth = JSON.parse(localStorage.getItem("userData"))
    let location = useLocation()
    console.log(location.state,"location .skhjhkjhj");
    var data = location.state ? location.state.result : null
    const [role, setRole] = React.useState({ title:location.state.role_title})
    const [error, setError] = React.useState({ title: "" })

    let name, value;
    const handleRole = (e) => {
        name = e.target.name;
        value = e.target.value
        setRole({ ...role, [name]: value })
    }

    const Validator = () => {
        if (!role.title) {
            setError({ title: "This field is required." })
            return false
        }
        return true
    }
    const handleBack = () =>{
        navigate('/role')
    }
    const handleSubmit = async () => {
        var Valid = Validator()
        if (Valid) {
            let body = {
                role_title: role.title.toString(),
     
                    addbooking: checkBooking.addBooking,
                    listbooking: checkBooking.listBooking,
                    editbooking: checkBooking.editBooking,
                    deletebooking: checkBooking.deleteBooking,
             
             
                    addtalent: checkTalents.addTalents,
                    listtalent: checkTalents.listTalents,
                    deletetalent: checkTalents.deleteTalents,
                    edittalent: checkTalents.editTalents,
                
             
                    addmodel: checkModels.addModels,
                    listmodel: checkModels.listModels,
                    editmodel:checkModels.editModels,
                    deletemodel: checkModels.deleteModels,
               
                    addstudio: checkStudio.addStudio,
                    liststudio: checkStudio.listStudio,
                    editstudio: checkStudio.editStudio,
                    deletestudio: checkStudio.deleteStudio,
              
                    chat: checkSubChat.chat,
              
                    addcustomer: checkCustemor.addCustemor,
                    listcustomer: checkCustemor.listCustemor,
                    deletecustomer: checkCustemor.deleteCustemor,
                    editcustomer: checkCustemor.editCustemor,
           
     
                    addservice: checkServiuce.addServiuce,
                    listservice: checkServiuce.viewServiuce,
                    editservice: checkServiuce.editServiuce,
                    deleteservice: checkServiuce.deleteServiuce,
              
    
                    addusers: checkUser.addUser,
                    listuser: checkUser.viewUser,
                    edituser: checkUser.editUser,
                    deleteuser: checkUser.deleteUser,
               
               
                    addcate: checkCategory.addCategory,
                    listcate: checkCategory.viewCategory,
                    editcate: checkCategory.editCategory,
                    deletecate: checkCategory.deleteCategory,

                    dashboard:checkDashboard.dashboard,
                    roleManagement:checkManagement.roleManagement
                
            }
            console.log(body,"bodyyy");
            axios.put(process.env.REACT_APP_BASE_URL +`roledata/updateroles/${location.state._id}`, body).then((res) => {
                enqueueSnackbar("Updated successfully", { variant: 'success' })
                navigate("/role")
                console.log(res,"roles addedd");
            }).catch((err) => {
                console.log(err);
                enqueueSnackbar(err.data.massage, { variant: 'error' })
            })
        }
    }

    // CHECKBOX
    const [checkBooking, setCheckBooking] = React.useState({ addBooking: location.state.addbooking, listBooking: location.state.listbooking, editBooking: location.state.editbooking, deleteBooking: location.state.deletebooking })
    const handleBookings = (e) => {
        if (e.target.name === 'deleteBooking') {
            setCheckBooking({ addBooking: e.target.checked, listBooking: e.target.checked, editBooking: e.target.checked, deleteBooking: e.target.checked })
        }
        if (e.target.name === 'editBooking') {
            setCheckBooking({ addBooking: e.target.checked, listBooking: e.target.checked, editBooking: e.target.checked })
        }
        if (e.target.name === 'addBooking') {
            setCheckBooking({ addBooking: e.target.checked, listBooking: e.target.checked })
        }
        if (e.target.name === 'listBooking') {
            setCheckBooking({ listBooking: e.target.checked })
        }
    }

    const [checkTalents, setCheckTalents] = React.useState({ addTalents: location.state.addtalent, listTalents: location.state.listtalent, editTalents: location.state.edittalent, deleteTalents: location.state.deletetalent })
    const handleCheckTalents = (e) => {
        if (e.target.name === 'deleteTalents') {
            setCheckTalents({ addTalents: e.target.checked, listTalents: e.target.checked, editTalents: e.target.checked, deleteTalents: e.target.checked })
        }
        if (e.target.name === 'edittalent') {
            setCheckTalents({ editTalents: e.target.checked, listTalents: e.target.checked, editTalents: e.target.checked })
        }
        if (e.target.name === 'addTalents') {
            setCheckTalents({ addTalents: e.target.checked, listTalents: e.target.checked })
        }
        if (e.target.name === 'listTalents') {
            setCheckTalents({ listTalents: e.target.checked })
        }
    }
    const [checkModels, setCheckModels] = React.useState({ addModels: location.state.addmodel, listModels: location.state.listmodel, editModels: location.state.editmodel, deleteModels: location.state.deletemodel })
    const handleCheckModels = (e) => {
        if (e.target.name === 'deleteModels') {
            setCheckModels({ addModels: e.target.checked, listModels: e.target.checked, editModels: e.target.checked, deleteModels: e.target.checked })
        }
        if (e.target.name === 'editModels') {
            setCheckModels({ addModels: e.target.checked, listModels: e.target.checked, editModels: e.target.checked })
        }
        if (e.target.name === 'addModels') {
            setCheckModels({ addModels: e.target.checked, listModels: e.target.checked })
        }
        if (e.target.name === 'listModels') {
            setCheckModels({ listModels: e.target.checked })
        }
    }

    const [checkStudio, setCheckStudio] = React.useState({ addStudio: location.state.addstudio, listStudio: location.state.liststudio, editStudio: location.state.editstudio, deleteStudio: location.state.deletestudio })
    const handleStudio = (e) => {
        if (e.target.name === 'deleteStudio') {
            setCheckStudio({ addStudio: e.target.checked, listStudio: e.target.checked, editStudio: e.target.checked, deleteStudio: e.target.checked })
        }
        if (e.target.name === 'editStudio') {
            setCheckStudio({ addStudio: e.target.checked, listStudio: e.target.checked, editStudio: e.target.checked })
        }
        if (e.target.name === 'addStudio') {
            setCheckStudio({ addStudio: e.target.checked, listStudio: e.target.checked })
        }
        if (e.target.name === 'listStudio') {
            setCheckStudio({ listStudio: e.target.checked })
        }
    }

    const [checkSubChat ,setCheckChat] = React.useState({ chat: location.state.chat})
    const handleCheckChat = (e) => {
            setCheckChat({ chat: e.target.checked})
    }

    const [checkUser, setCheckUser] = React.useState({ addUser: location.state.addusers, viewUser: location.state.listuser, editUser: location.state.edituser, deleteUser: location.state.deleteuser })
    const handleCheckUser = (e) => {
        if (e.target.name === 'deleteUser') {
            setCheckUser({ addUser: e.target.checked, viewUser: e.target.checked, editUser: e.target.checked, deleteUser: e.target.checked })
        }
        if (e.target.name === 'editUser') {
            setCheckUser({ addUser: e.target.checked, viewUser: e.target.checked, editUser: e.target.checked })
        }
        if (e.target.name === 'addUser') {
            setCheckUser({ addUser: e.target.checked, viewUser: e.target.checked })
        }
        if (e.target.name === 'viewUser') {
            setCheckUser({ viewUser: e.target.checked })
        }
    }

    const [checkCustemor, setCheckCustemor] = React.useState({ addCustemor: location.state.addcustomer, listCustemor: location.state.listcustomer, editCustemor: location.state.editcustomer, deleteCustemor: location.state. deletecustomer })
    const handleCheckCustemor = (e) => {
        if (e.target.name === 'deleteCustemor') {
            setCheckCustemor({ addCustemor: e.target.checked, listCustemor: e.target.checked, editCustemor: e.target.checked, deleteCustemor: e.target.checked })
        }
        if (e.target.name === 'editCustemor') {
            setCheckCustemor({ addCustemor: e.target.checked, listCustemor: e.target.checked, editCustemor: e.target.checked })
        }
        if (e.target.name === 'addCustemor') {
            setCheckCustemor({ addCustemor: e.target.checked, listCustemor: e.target.checked })
        }
        if (e.target.name === 'listCustemor') {
            setCheckCustemor({ listCustemor: e.target.checked })
        }
    }

    const [checkServiuce, setCheckServiuce] = React.useState({addServiuce: location.state.addservice, viewServiuce: location.state.listservice, editServiuce: location.state.editservice, deleteServiuce: location.state.deleteservice})
    const handleCheckServiuce= (e) => {
        if (e.target.name === 'deleteServiuce') {
            setCheckServiuce({ addServiuce: e.target.checked, viewServiuce: e.target.checked, editServiuce: e.target.checked, deleteServiuce: e.target.checked })
        }
        if (e.target.name === 'editServiuce') {
            setCheckServiuce({ addServiuce: e.target.checked, viewServiuce: e.target.checked, editServiuce: e.target.checked })
        }
        if (e.target.name === 'addServiuce') {
            setCheckServiuce({ addServiuce: e.target.checked, viewServiuce: e.target.checked })
        }
        if (e.target.name === 'viewServiuce') {
            setCheckServiuce({ viewServiuce: e.target.checked })
        }
    }

    const [checkCategory, setCheckCategory] = React.useState({addCategory: location.state.addcate, viewCategory: location.state.listcate, editCategory: location.state.editcate, deleteCategory: location.state.deletecate})
    const handleCheckCategory = (e) => {
        if (e.target.name === 'deleteCategory') {
            setCheckCategory({ addCategory: e.target.checked, viewCategory: e.target.checked, editCategory: e.target.checked, deleteCategory: e.target.checked })
        }
        if (e.target.name === 'editCategory') {
            setCheckCategory({ addCategory: e.target.checked, viewCategory: e.target.checked, editCategory: e.target.checked })
        }
        if (e.target.name === 'addCategory') {
            setCheckCategory({ addCategory: e.target.checked, viewCategory: e.target.checked })
        }
        if (e.target.name === 'viewCategory') {
            setCheckCategory({ viewCategory: e.target.checked })
        }
    }

    const [checkDashboard, setCheckDashboard] = React.useState({dashboard: location.state.dashboard})
    const handleCheckdashboard = (e) => {
        console.log(e.target.name,"dhhhhhhhhhh");
        if (e.target.name === 'dashboard') {
            setCheckDashboard({ dashboard: e.target.checked, })
        }
    }

    const [checkManagement, setCheckroles] = React.useState({roleManagement: location.state.roleManagement})
    const handleCheckroles = (e) => {
        if (e.target.name === 'roleManagement') {
            setCheckroles({ roleManagement: e.target.checked, })
        }
    }


    return (
        <Box className='Addcompany'>
            <MiniDrawer type={2} />
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Edit Role</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" >
                                            <Typography component='div' className='label'>Role name <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Title'
                                                name="title"
                                                value={role.title}
                                                onChange={(e) => { handleRole(e); setError({ title: "" }) }}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                            {error.title && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.title}</p>}
                                        </FormControl>
                                    </Grid>


                                    {/* FROM PAX */}
                                    <Grid item xs={12} sm={12} md={12} lg={12} className='res-grid'>
                                        <Box >
                                            <Typography className=' fz-18 semibold' component='h4' variant='h4'>Permissions</Typography>
                                            <Box className='mt-30'>
                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Service</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Services </Typography>} control={<Checkbox onChange={handleCheckServiuce} name="addServiuce" checked={checkServiuce.addServiuce} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Services </Typography>} control={<Checkbox onChange={handleCheckServiuce} name="viewServiuce" checked={checkServiuce.viewServiuce} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Services </Typography>} control={<Checkbox onChange={handleCheckServiuce} name="editServiuce" checked={checkServiuce.editServiuce} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Services </Typography>} control={<Checkbox onChange={handleCheckServiuce} name="deleteServiuce" checked={checkServiuce.deleteServiuce} size='small' />} />
                                                    </Box>
                                                </Box>
                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Bookings</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Bookings </Typography>} control={<Checkbox onChange={handleBookings} name="addBooking" checked={checkBooking.addBooking} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Bookings </Typography>} control={<Checkbox onChange={handleBookings} name="listBooking" checked={checkBooking.listBooking} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Bookings </Typography>} control={<Checkbox onChange={handleBookings} name="editBooking" checked={checkBooking.editBooking} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Bookings </Typography>} control={<Checkbox onChange={handleBookings} name="deleteBooking" checked={checkBooking.deleteBooking} size='small' />} />
                                                    </Box>
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Talents</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Talents </Typography>} control={<Checkbox onChange={handleCheckTalents} name="addTalents" checked={checkTalents.addTalents} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Talents </Typography>} control={<Checkbox onChange={handleCheckTalents} name="listTalents" checked={checkTalents.listTalents} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Talents </Typography>} control={<Checkbox onChange={handleCheckTalents} name="editTalents" checked={checkTalents.editTalents} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Talents </Typography>} control={<Checkbox onChange={handleCheckTalents} name="deleteTalents" checked={checkTalents.deleteTalents} size='small' />} />
                                                    </Box>
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Models</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Models </Typography>} control={<Checkbox onChange={handleCheckModels} name="addModels" checked={checkModels.addModels} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Models </Typography>} control={<Checkbox onChange={handleCheckModels} name="listModels" checked={checkModels.listModels} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Models </Typography>} control={<Checkbox onChange={handleCheckModels} name="editModels" checked={checkModels.editModels} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Models </Typography>} control={<Checkbox onChange={handleCheckModels} name="deleteModels" checked={checkModels.deleteModels} size='small' />} />
                                                    </Box>
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Studio</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Studios </Typography>} control={<Checkbox onChange={handleStudio} name="addStudio" checked={checkStudio.addStudio} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Studios </Typography>} control={<Checkbox onChange={handleStudio} name="listStudio" checked={checkStudio.listStudio} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Studios </Typography>} control={<Checkbox onChange={handleStudio} name="editStudio" checked={checkStudio.editStudio} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Studios </Typography>} control={<Checkbox onChange={handleStudio} name="deleteStudio" checked={checkStudio.deleteStudio} size='small' />} />
                                                    </Box>
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Chat</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Chat </Typography>} control={<Checkbox onChange={handleCheckChat} name="addSubcategory" checked={checkSubChat.chat} size='small' />} />
                                                    </Box>
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Custemor management</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Customers </Typography>} control={<Checkbox onChange={handleCheckCustemor} name="addCustemor" checked={checkCustemor.addCustemor} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Customers </Typography>} control={<Checkbox onChange={handleCheckCustemor} name="listCustemor" checked={checkCustemor.listCustemor} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Customers </Typography>} control={<Checkbox onChange={handleCheckCustemor} name="editCustemor" checked={checkCustemor.editCustemor} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Customers </Typography>} control={<Checkbox onChange={handleCheckCustemor} name="deleteCustemor" checked={checkCustemor.deleteCustemor} size='small' />} />
                                                    </Box>
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>User management</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Users </Typography>} control={<Checkbox onChange={handleCheckUser} name="addUser" checked={checkUser.addUser} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Users </Typography>} control={<Checkbox onChange={handleCheckUser} name="viewUser" checked={checkUser.viewUser} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Users </Typography>} control={<Checkbox onChange={handleCheckUser} name="editUser" checked={checkUser.editUser} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Users </Typography>} control={<Checkbox onChange={handleCheckUser} name="deleteUser" checked={checkUser.deleteUser} size='small' />} />
                                                    </Box>
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Category</Typography>
                                                <Box sx={{ display: "flex", flexWrap: "wrap" }} className="">
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Add Categories </Typography>} control={<Checkbox onChange={handleCheckCategory} name="addCategory" checked={checkCategory.addCategory} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >View Categories </Typography>} control={<Checkbox onChange={handleCheckCategory} name="viewCategory" checked={checkCategory.viewCategory} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Edit Categories </Typography>} control={<Checkbox onChange={handleCheckCategory} name="editCategory" checked={checkCategory.editCategory} size='small' />} />
                                                    </Box>
                                                    <Box sx={{ width: '250px' }}>
                                                        <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >Delete Categories </Typography>} control={<Checkbox onChange={handleCheckCategory} name="deleteCategory" checked={checkCategory.deleteCategory} size='small' />} />
                                                    </Box>
                                                </Box>

                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Dashboard</Typography>
                                                <Box sx={{ width: '250px' }}>
                                                    <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >dashboard</Typography>} control={<Checkbox onChange={handleCheckdashboard} name="dashboard" checked={checkDashboard.dashboard} size='small' />} />
                                                </Box>


                                                <Typography className=' fz-16 semibold' sx={{ marginTop: '15px' }} component='h5' variant='h5'>Roles</Typography>

                                                <Box sx={{ width: '250px' }}>
                                                    <FormControlLabel label={<Typography component='div' className='fz-14 flex-box' >role Management</Typography>} control={<Checkbox onChange={handleCheckroles} name="roleManagement" checked={checkManagement.roleManagement} size='small' />} />
                                                </Box>    


                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                                <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>
                                <Button variant="contained" className='addbtn mr-10' onClick={handleBack}>Back</Button>

                                    <Button onClick={handleSubmit} variant="contained" className='addbtn mr-10'>Update</Button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}






