import React from 'react';
import {
    Box, Typography, Grid, Avatar, Card, CardContent, Tabs, Tab,
    IconButton
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/swiper-bundle.css';
import ImageIcon from '@mui/icons-material/Image';
import VideocamIcon from '@mui/icons-material/Videocam';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CancelIcon from '@mui/icons-material/Cancel';
import DOMPurify from 'dompurify';
import MiniDrawer from '../../../components/drawer';
import Modal from '../../Home/Models/model'

const AddTalent = () => {
    let navigate = useNavigate();
    let location = useLocation();

    const [allDetails, setAllDetails] = React.useState('');
    const [details, setDetails] = React.useState('Photo');
    const [calenderData, setCalenderData] = React.useState([]);
    const [images, setImages] = React.useState([]);
    console.log(images,"Images for the data")
    const [videos, setVideos] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const [currentImage, setCurrentImage] = React.useState(null);

    React.useEffect(() => {
        getModeleDetailes();
        Mediadata();
        getBookings();
    }, []);

    const getModeleDetailes = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_BASE_URL + `adminmodels/getmodeldetailsbyid123/${location.state._id}`);
            setAllDetails(res.data.modeldetails);
        } catch (error) {
            console.error('Error fetching model details:', error);
        }
    };

    const Mediadata = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_BASE_URL + `followandlike/gettsmmedia/${location.state._id}`);
            const mediaData = response.data.media.media;

            const flatMediaArray = mediaData.flat();

            const processedImages = flatMediaArray
                .filter(item => item.mimetype.includes('image'))
                .map(item => item.path);

            const processedVideos = flatMediaArray
                .filter(item => item.mimetype.includes('video'))
                .map(item => item.path);

            setImages(processedImages);
            setVideos(processedVideos);

        } catch (error) {
            console.error('Error fetching media data:', error);
        }
    };

    const getBookings = async () => {
        try {
            const res = await axios.get(process.env.REACT_APP_BASE_URL + `paymentsdata/getthebookings`);
            setCalenderData(res.data.data);
        } catch (error) {
            console.error('Error fetching bookings:', error);
        }
    };

    const sanitizedContent = DOMPurify.sanitize(allDetails.description, { ALLOWED_TAGS: ['img'] });

    const toggleModal = (imagePath) => {
        setIsOpen(!isOpen);
        setCurrentImage(imagePath);
      };

    return (
        <Box className='Addcompany'>
            <MiniDrawer type={7} />
            <Box component="section" className="contentWraper">
                <Box className=' whiteContainer'>
                    <Box className='content p-20'>
                        <Typography component='h6' className='formTitle'>View Model</Typography>

                        <Grid style={{ marginBottom: 15 }} item xs={12} sm={12} md={6} lg={6}>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                <div className="carosuel mb-10">
                                    <Avatar>
                                        <img width={'100%'} height={250} src={process.env.REACT_APP_BASE_URL + `${allDetails && allDetails.profile}`} alt="Profile" />
                                    </Avatar>
                                </div>
                            </Grid>
                            <Box className='mb-10'>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Box className='d-flex align-items-center'>
                                            <Box>
                                                <Typography variant='body1' className='fz-14 semibold'>Modelname </Typography>
                                                <Typography variant='body1' className='fz-14'>{allDetails.name || "-"}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant='body1' className='fz-15 semibold'>Category </Typography>
                                        <Typography variant='body1' className='fz-15'>{allDetails.Category_id?.length ? allDetails.Category_id.map(data => data.title).join(', ') : "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant='body1' className='fz-14 semibold'>Height </Typography>
                                        <Typography variant='body1' className='fz-14'>{allDetails.modelInfo?.Height || "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant='body1' className='fz-14 semibold'>Weight </Typography>
                                        <Typography variant='body1' className='fz-14'>{allDetails.modelInfo?.weight || "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant='body1' className='fz-14 semibold'>Body Type </Typography>
                                        <Typography variant='body1' className='fz-14'>{allDetails.modelInfo?.body_type || "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant='body1' className='fz-14 semibold'>Color </Typography>
                                        <Typography variant='body1' className='fz-14'>{allDetails.modelInfo?.skin_color || "-"}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={4}>
                                        <Typography variant='body1' className='fz-14 semibold'>Location </Typography>
                                        <Typography variant='body1' className='fz-14'>{allDetails.location || "-"}</Typography>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Grid container columnSpacing={2} rowSpacing={1} className=" mb-10 align-items-center">
                                <Box className='flex-box mb-10 segmentControl' style={{ justifyContent: 'start', width: '100%' }}>
                                    <Tabs
                                        value={details}
                                        onChange={(event, newValue) => setDetails(newValue)}
                                        textColor="secondary"
                                        indicatorColor="secondary"
                                        aria-label="secondary tabs example"
                                        className='segmentControl'
                                        sx={{ '& .MuiTabs-indicator': { display: 'none' }, width: '100%' }}
                                    >
                                        <Tab className='subsegment' sx={{ textTransform: "none", width: '80%' }} value="Photo" icon={<ImageIcon />} />
                                        <Tab className='subsegment' sx={{ textTransform: "none", width: '100%' }} value="Video" icon={<VideocamIcon />} />
                                        <Tab className='subsegment' sx={{ textTransform: "none", width: '100%' }} value="calendar" icon={<CalendarMonthIcon />} />
                                    </Tabs>
                                </Box>

                                {/* {details === 'Photo' && (
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {images.length > 0 ? (
                                            images.map((imagePath, i) => (
                                                <Card key={i} className="m-2" style={{ flex: '0 0 calc(33.33% - 1rem)' }}>
                                                    <Swiper
                                                        modules={[Navigation, Pagination]}
                                                        className="mySwiper"
                                                        slidesPerView={1}
                                                        pagination={{ clickable: true }}
                                                        navigation
                                                    >
                                                        <SwiperSlide>
                                                            <img
                                                                style={{ width: '100%', height: 'auto' }}
                                                                src={process.env.REACT_APP_BASE_URL + imagePath}
                                                                alt={`Image ${i}`}
                                                            />
                                                        </SwiperSlide>
                                                    </Swiper>
                                                </Card>
                                            ))
                                        ) : (
                                            <Typography>No images available</Typography>
                                        )}
                                    </div>
                                )} */}

                                {details === 'Photo' && (
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {images.length > 0 ? (
                                            images.map((imagePath, i) => (
                                                <Card key={i} className="m-2" style={{ flex: '0 0 calc(33.33% - 1rem)' }}>
                                                    <img
                                                        style={{ width: '100%', height: 'auto' }}
                                                        src={process.env.REACT_APP_BASE_URL + imagePath}
                                                        alt={`Image ${i}`}
                                                        onClick={() => toggleModal(imagePath)}
                                                    />
                                                </Card>
                                            ))
                                        ) : (
                                            <Typography>No images available</Typography>
                                        )}
                                    </div>
                                )}

                                {isOpen && currentImage &&(
                                <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                                    {currentImage && (
                                        <img
                                            style={{ width: '100%', height: 'auto' }}
                                            src={process.env.REACT_APP_BASE_URL + currentImage}
                                            alt={`Viewed Image`}
                                        />
                                    )}
                                </Modal>
                                )}

                                {details === 'Video' && (
                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        {videos.length > 0 ? (
                                            videos.map((videoPath, i) => (
                                                <Card key={i} className="m-2" style={{ flex: '0 0 calc(33.33% - 1rem)' }}>
                                                    <video controls width="100%">
                                                        <source src={process.env.REACT_APP_BASE_URL + videoPath} type="video/mp4" />
                                                        Your browser does not support the video tag.
                                                    </video>
                                                </Card>
                                            ))
                                        ) : (
                                            <Typography>No videos available</Typography>
                                        )}
                                    </div>
                                )}

                                {details === 'calendar' && (
                                    <div>
                                        {calenderData.length > 0 ? (
                                            calenderData.map((item, i) => (
                                                <Card key={i} className="mb-2">
                                                    <CardContent>
                                                        <Typography variant='h6'>{item.eventTitle}</Typography>
                                                        <Typography>{moment(item.eventDate).format('MMMM Do YYYY')}</Typography>
                                                        <Typography>{item.eventDescription}</Typography>
                                                    </CardContent>
                                                </Card>
                                            ))
                                        ) : (
                                            <Typography>No bookings available</Typography>
                                        )}
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default AddTalent;
