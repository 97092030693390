import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Input, Tabs, Tab } from '@mui/material'
import React from 'react'
import MiniDrawer from '../../../components/drawer'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AddIcCall, SearchOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import axios from 'axios';
import { Card, CardContent } from "@mui/material";
import { useSnackbar, SnackbarProvider } from 'notistack'
import VideocamIcon from '@mui/icons-material/Videocam';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import { OutlinedInput } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { ListItemText } from '@mui/material';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CompareIcon from '@mui/icons-material/Compare';
import Check from '@mui/icons-material/Check';
import DashboardIcon from '@mui/icons-material/Dashboard';
import MmsIcon from '@mui/icons-material/Mms';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
// for service page 
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Tooltip from '@mui/material/Tooltip';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import DialogContent from '@mui/material/DialogContent';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// -----------------------------------------------------------------------------------------------------------
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#3554D1',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#3554D1',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#3554D1',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;
    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}
QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};
const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#3554D1',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#3554D1',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#3554D1',
        // backgroundColor:
        //     theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#3554D1',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#545454',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#3554D1',
    }),
}));

function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <DashboardIcon />,
        2: <MmsIcon />,
        3: <Dvr />,
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};
const stripePromise = loadStripe('pk_test_51PJUGISAkKmdrZwY1ONClLOMNxxCARBSJYKc1w9LtnFQZrIHXVE03L95vq1TxqCej8R1aAcAX0nxow1Of6MJvyxb00dCkDGQ5U');
const AddModels = () => {
    const steps = ['Basic Information', 'Additional Information'];
    const [value, setValue] = React.useState(dayjs(new Date()));
    let admindetails = JSON.parse(localStorage.getItem("userData"))
    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [imageAddError, setImageAddError] = React.useState('')
    const [state1, setState1] = React.useState([])
    const [state2, setState2] = React.useState([])
    const [state3, setState3] = React.useState([])
    const [state4, setState4] = React.useState([])
    const [profImg1, setProfImg1] = React.useState([])
    const [profImg2, setProfImg2] = React.useState([])
    const [profImg3, setProfImg3] = React.useState([])
    const [profImg4, setProfImg4] = React.useState([])
    const [buttonview, setButtonview] = React.useState(false)
    const [buttonview1, setButtonview1] = React.useState(true)
    const [model_description, setModel_description] = React.useState('')
    const [activeStep, setActiveStep] = React.useState(0);
    const [categoryDetails, setCategoryDetails] = React.useState([])
    const [modelname, setModelName] = React.useState('')
    // const [username, setUserName] = React.useState('')
    const [weight, setWeight] = React.useState('')
    const [email, setEmail] = React.useState('')
    const [price, setPrice] = React.useState('')
    const [hight, setHight] = React.useState('')
    const [bodyType, setBodyType] = React.useState('')
    const [color, setColor] = React.useState('')
    const [eyes, setEyes] = React.useState('')
    const [age, setAge] = React.useState('')
    // const [height,setHeight] = React.useState('')
    const [hips, setHips] = React.useState('')
    const [hair, sethair] = React.useState('')
    const [dress, setDress] = React.useState('')
    const [bust, setBust] = React.useState('')
    const [waist, setWaist] = React.useState('')
    const [shoe, setShoe] = React.useState('')
    const [category, setCategory] = React.useState('')
    const [location, setLocation] = React.useState('')
    const [city, setCity] = React.useState('')
    const [error, setError] = React.useState({ name: "", service: "", email: "", Category: "" })
    const [viewpage, setViewpage] = React.useState(1)
    const [video1, setVideo1] = React.useState([])
    const [video2, setVideo2] = React.useState([])
    const [addImg1, setAddImg1] = React.useState([])
    const [addImg2, setAddImg2] = React.useState([])
    const [personName, setPersonName] = React.useState([])
    const [personNameService, setPersonNameService] = React.useState([])
    const [images, setImages] = React.useState({})
    const [videos, setVideos] = React.useState({})
    console.log(images, "imagesdata");
    console.log(videos, "imagesdata");
    const [richeditor, setRichtor] = React.useState("")
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const [editorState1, setEditorState1] = React.useState(EditorState.createEmpty());

    const dataofeditor = `${richeditor}`
    const [isPrimary, setIsPrimary] = React.useState(false)
    const [aditnalValues, setaditnalValues] = React.useState(1)

    const [servicename, setServiceName] = React.useState('')
    const [duration, setDuration] = React.useState('perservice')
    const [date, setDate] = React.useState(dayjs(new Date()));
    const [modleId, setModelId] = React.useState('')
    const [selectedFromDateTime, setSelectedFromDateTime] = React.useState(dayjs(new Date()));
    const [selectedToDateTime, setSelectedToDateTime] = React.useState(dayjs(new Date()));
    const [selectedItem, setSelectedItem] = React.useState('');

    // -------------------service page
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [serviceDetails, setServiceDetails] = React.useState([]);
    const [deleteid, setDeleteId] = React.useState('');

    const [open, setOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [status, setStatus] = React.useState("all");
    const [openview, setOpenview] = React.useState(false);
    const [alldata, setAlldata] = React.useState('');
    const [showAddModule, setShowAddModule] = React.useState(false)

    const [cardOpen, setCardOpen] = React.useState(false);
    const stripe = useStripe();
    const elements = useElements();
    const [video11, setVideo11] = React.useState([]);
    const [images11, setImages11] = React.useState([]);

    // ----------------------------------------------------------------------
    React.useEffect(() => {

        if (dataofeditor) {
            try {
                const contentState = stateFromHTML(dataofeditor);
                setEditorState(EditorState.createWithContent(contentState));
            } catch (error) {
                console.error("Error during HTML to ContentState conversion:", error);
            }
        }
    }, [dataofeditor]);



    React.useEffect(() => {
        getCategory()
    }, [])


    React.useEffect(() => {
        convertContentToHTML();
    }, [editorState]);

    const [convertedContent, setConvertedContent] = React.useState(null);

    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
    }

    const convertContentToHTML = () => {
        const currentContent = editorState.getCurrentContent();
        const currentContentAsHTML = stateToHTML(currentContent);
        setConvertedContent(currentContentAsHTML)
    }


    React.useEffect(() => {
        convertContentToHTML1();
    }, [editorState1]);

    const [convertedContent1, setConvertedContent1] = React.useState(null);

    const handleEditorChange1 = (newEditorState) => {
        setEditorState1(newEditorState);
    }

    const convertContentToHTML1 = () => {
        const currentContent1 = editorState1.getCurrentContent();
        const currentContentAsHTML1 = stateToHTML(currentContent1);
        setConvertedContent1(currentContentAsHTML1)
    }
    React.useEffect(() => {
        getPhotoes();
        getVideos();
    }, [])
    const getPhotoes = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `followandlike/getphotosbyid/${modleId}`)
            .then((res) => {
                setImages(res.data.allimage)
            })
            .catch((error) => {

            })
    }
    const getVideos = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `followandlike/getvideosbyid/${modleId}`)
            .then((res) => {

                setVideos(res.data.allvideos)

            })
            .catch((error) => {

            })
    }



    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `admincategory/getallcategories?isdropdown=true`)
            .then((res) => {
                console.log(res.data, "get category");
                setCategoryDetails(res.data.data)
            })
            .catch((error) => {
                console.log(error, "error in get category");
            })
    }

    const profileImageChange = (e, index) => {

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setProfImg1(prevValue => prevValue.concat(fileArray))
        setProfImg2(prevValue => prevValue.concat(filepath))
    }

    const DeleteProf = (e, index) => {
        var filteredpreview = profImg1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = profImg2.filter((val, i) => {
            return i !== index;
        });
        setProfImg1(filteredpreview);
        setProfImg2(filteredraw);
    }

    // for  add sercvicee
    const profileImageChangeService = (e, index) => {
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setProfImg3(prevValue => prevValue.concat(fileArray))
        setProfImg4(prevValue => prevValue.concat(filepath))
    }

    const DeleteProfService = (e, index) => {
        var filteredpreview = profImg3.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = profImg4.filter((val, i) => {
            return i !== index;
        });
        setProfImg3(filteredpreview);
        setProfImg4(filteredraw);
    }








    // service Aditional files
    const serviceAditionalFiles = (e, index) => {
        setImageAddError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setState3(prevValue => prevValue.concat(fileArray))
        setState4(prevValue => prevValue.concat(filepath))
    }

    const indexDeleteService = (e, index) => {
        var filteredpreview = state3.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state4.filter((val, i) => {
            return i !== index;
        });
        setState3(filteredpreview);
        setState4(filteredraw);
    }






    const multipleImgChange = (e, index) => {
        setImageAddError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setState1(prevValue => prevValue.concat(fileArray))
        setState2(prevValue => prevValue.concat(filepath))
    }

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }


    //additionsl images

    const AdditionalImgChange = (e) => {
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setAddImg1(prevValue => prevValue.concat(fileArray))
        setAddImg2(prevValue => prevValue.concat(filepath))
    }


    const additionalDelete = (e, index) => {
        var filteredpreview = addImg1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = addImg2.filter((val, i) => {
            return i !== index;
        });
        setAddImg1(filteredpreview);
        setAddImg2(filteredraw);
    }


    const AdditionalVideoChange = (e, index) => {
        // var fileObj = [];
        // var fileArray = [];
        // var filepath = [];

        // fileObj.push(e.target.files)
        // for (let i = 0; i <= fileObj[0].length; i++) {
        //     if (i < fileObj[0].length) {
        //         fileArray.push(URL.createObjectURL(fileObj[0][i]))
        //         filepath.push(fileObj[0][i])
        //     }
        // }
        // setVideo1(prevValue => prevValue.concat(fileArray))
        // setVideo2(prevValue => prevValue.concat(filepath))

        
        const files = Array.from(event.target.files);
        const videos = files.filter(file => file.type.includes('video'));
        const imgs = files.filter(file => file.type.includes('image'));

        setVideo11(prev => [...prev, ...videos]);
        setImages11(prev => [...prev, ...imgs]);
    }

    const indexVideoDelete = (e, index) => {

        var filteredpreview = video1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = video2.filter((val, i) => {
            return i !== index;
        });
        setVideo1(filteredpreview);
        setVideo2(filteredraw);
    }

    const indexVideoDelete12 = (e, index) => {
        e.preventDefault();
        setVideo11(prev => prev.filter((_, i) => i !== index));
    };

    const indexImageDelete = (e, index) => {
        e.preventDefault();
        setImages11(prev => prev.filter((_, i) => i !== index));
    };


    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const Validation = () => {
        if (!modelname && profImg2?.length == 0 && personName?.length == 0) {
            setError({ name: "This field is required.", service: "This field is required.", email: "This field is required." })
            return false
        }
        if (!modelname) {
            setError({ name: "This field is required." })
            return false
        }
        // if (!username) {
        //     setError({ username: "This field is required." })
        //     return false
        // }
        if (!email) {
            setError({ email: "This field is required." })
            return false
        }
        if (email) {
            if (!emailRegex.test(String(email).toLowerCase())) {
                setError({ email: "Please enter valid email address" })
                return false
            }
        }




        return true
    }

    const ValidationService = () => {
        if (!servicename && personNameService?.length == 0) {
            setError({ name: "This field is required.", Category: "This field is required." })
            return false
        }
        if (!servicename) {
            setError({ name: "This field is required." })
            return false
        }


        if (personNameService?.length == 0) {
            setError({ Category: "This field is required." })
            return false
        }
        return true
    }


    const headerformdata = {
        'Content-Type': 'multipart/form-data',
    }


    const CreateService = async () => {
        let valid = Validation()
        if (valid) {

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                type: 'card',
                card: elements.getElement(CardElement),
            });

            let categoryid = personName.map((data) => data._id)
            
            setButtonview(true)
            const formData = new FormData();
            formData.append("name", modelname)
            formData.append("isAdmin", true)
            formData.append("isStatus", true)
            formData.append("isService", false)
            // formData.append("Category_id", categoryid[0])
            formData.append("admin_id", admindetails._id)
            formData.append("location", location)
            formData.append("email", email)
            // formData.append("username", username)
            formData.append("description", convertedContent)
            formData.append("city", '')
            formData.append("state", '')
            formData.append("pin", '')
            formData.append("role", 'Model')
            formData.append("isOnline", false)
            formData.append("address", '')
            formData.append("weight", weight)
            formData.append("skin_color", color)
            formData.append("Height", hight)
            formData.append("body_type", bodyType)
            formData.append("dress", dress)
            formData.append("bust", bust)
            formData.append("waist", waist)
            formData.append("hips", hips)
            formData.append("shoe", shoe)
            formData.append("hair", hair)
            formData.append("eyes", eyes)
            formData.append("paymentMethodId", paymentMethod?paymentMethod.id:'')

            // formData.append("password", '1234')
            { profImg2?.length > 0 ? profImg2.forEach((item) => { formData.append("profile", item) }) : formData.append("profile", '') }
            { state2?.length > 0 ? state2.forEach((item) => { formData.append("converimage", item) }) : formData.append("converimage", '') }
            console.log(formData,"console for formdata")
            await axios.post(process.env.REACT_APP_BASE_URL + `talents/addtalentsdeta`, formData, { headers: headerformdata })
                .then((res) => {
                    console.log(res.data.modelId, "res");
                    let mId = res.data.modelId
                    setModelId(mId)
                    setButtonview(false)
                    navigate('/models')
                    enqueueSnackbar("Added Successfully", { variant: 'success' })
                })
                .catch((error) => {
                    setButtonview(false)
                    console.log(error, "eeee");
                    enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })

                })
        }

    }


    const handleCardDetailes = () => {
        setCardOpen(true)
    }


    const SubmitContinue = async () => {
        let valid = Validation()
        if (valid) {
            setButtonview(true)
            setButtonview1(true)
            let categoryid = personName.map((data) => data._id)

            const formData = new FormData();
            formData.append("name", modelname)
            formData.append("isAdmin", true)
            formData.append("isStatus", true)
            formData.append("isService", false)
            // formData.append("Category_id", categoryid)66cc1c60e35d30fad4c6b9b0
            // formData.append("Category_id", '66cc1c60e35d30fad4c6b9b0')
            formData.append("admin_id", admindetails._id)
            formData.append("location", location)
            formData.append("email", email)
            // formData.append("username", username)
            formData.append("description", convertedContent)
            formData.append("city", '')
            formData.append("state", '')
            formData.append("pin", '')
            formData.append("role", 'Model')
            formData.append("isOnline", false)
            formData.append("address", '')
            formData.append("weight", weight)
            formData.append("skin_color", color)
            formData.append("Height", hight)
            formData.append("body_type", bodyType)
            formData.append("dress", dress)
            formData.append("bust", bust)
            formData.append("Waist", waist)
            formData.append("Hips", hips)
            formData.append("shoe", shoe)
            formData.append("hair", hair)
            formData.append("eyes", eyes)
            formData.append("Age", age)

            // formData.append("password", '1234')
            { profImg2?.length > 0 ? profImg2.forEach((item) => { formData.append("profile", item) }) : formData.append("profile", '') }
            { state2?.length > 0 ? state2.forEach((item) => { formData.append("converimage", item) }) : formData.append("converimage", '') }
            await axios.post(process.env.REACT_APP_BASE_URL + `talents/addtalentsdeta`, formData, { headers: headerformdata })
                .then((res) => {
                    console.log(res.data.modelId, "res");
                    let mId = res.data.modelId
                    setButtonview1(false)
                    setViewpage(2)
                    setModelId(mId)
                    setButtonview(false)
                })
                .catch((error) => {
                    setButtonview1(false)
                    setButtonview(false)
                    console.log(error, "eeee");
                    enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })

                })
        }

    }



    const handleMultipleSelect = (event, item) => {
        setError({ service: '' })
        const { target: { value }, } = event;
        const idCounts = value.reduce((acc, item) => {
            acc[item._id] = (acc[item._id] || 0) + 1;
            return acc;
        }, {});

        const filteredData = value.filter(item => idCounts[item._id] === 1);
        setPersonName(filteredData);
    }


    const handleMultipleServiceSelect = (event, item) => {
        setError({ service: '' })
        const { target: { value }, } = event;
        const idCounts = value.reduce((acc, item) => {
            acc[item._id] = (acc[item._id] || 0) + 1;
            return acc;
        }, {});

        const filteredData = value.filter(item => idCounts[item._id] === 1);
        setPersonNameService(filteredData);
    }


    const handleMenuItemSelect = (event) => {
        setColor(event.target.value);

    };
    const handleBodyTypeselect = (event) => {
        setBodyType(event.target.value);

    };
    const handleEyeColoer = (event) => {
        setEyes(event.target.value);

    };
    const handleHairColor = (event) => {
        sethair(event.target.value);

    };


    const Forservice = async () => {
        let abc = ValidationService()
        if (abc) {
            ServiceAdd()
        }
    }
    const handleUplode = () => {
        setButtonview(true)
        // if (addImg2.length > 0) ImageUpDateMethod();
        VideoUpdateMethod();
        navigate('/models')
    }


    const ImageUpDateMethod = () => {
        return new Promise((resolve, reject) => {
            const formDataservice = new FormData();
            formDataservice.append("createdBy", modleId)
            { addImg2?.length > 0 ? addImg2.forEach((item) => { formDataservice.append("images", item) }) : formDataservice.append("images", '') }
            axios.post(process.env.REACT_APP_BASE_URL + 'followandlike/addtsmpost', formDataservice, { headers: headerformdata })
                .then(res => {
                    // enqueueSnackbar("Added Successfully", { variant: 'success' })
                    resolve(res.data)
                })
                .catch(err => {
                    enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
                    reject(err)
                })
        })
    }

    // const VideoUpdateMethod = () => {
    //     return new Promise((resolve, reject) => {
    //         const formDataservice = new FormData();
    //         formDataservice.append("createdBy", modleId)
    //         { video2?.length > 0 ? video2.forEach((item) => { formDataservice.append("videos", item) }) : formDataservice.append("videos", '') }
    //         axios.post(process.env.REACT_APP_BASE_URL + 'followandlike/addtsmvideo', formDataservice, { headers: headerformdata })
    //             .then((res) => {
    //                 console.log(res, "res");
    //                 enqueueSnackbar("Added Successfully", { variant: 'success' })
    //                 resolve(res.data)

    //             })
    //             .catch((error) => {
    //                 reject(error)
    //                 enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })

    //             })
    //     })
    // }

    const VideoUpdateMethod = () => {
        console.log(video11,'video11',images11,modleId)
            var formdata = new FormData();
            formdata.append("user_id", modleId);
            { video11?.length > 0 ? video11.forEach((item) => { formdata.append("media", item) }) : formdata.append("media", '') }
            { images11?.length > 0 ? images11.forEach((item) => { formdata.append("media", item) }) : formdata.append("media", '') }

            for (var key of formdata.entries()) {
                console.log(key[0] + ', ' + key[1])
            }
            axios.post(process.env.REACT_APP_BASE_URL + 'followandlike/addtsmvideo', formdata, { headers: headerformdata })
                .then((res) => {
                    console.log(res, "resin videos");
                    enqueueSnackbar("Added Successfully", { variant: 'success' });
                })
                .catch((error) => {
                    enqueueSnackbar(error.message === 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' });
                });
       
    };


  

    const ServiceAdd = async () => {
        let serviceCategory_id = personNameService.map((data) => data._id)
        setButtonview(true)
        setButtonview1(true)
        const formDataservice = new FormData();
        formDataservice.append("servicename", servicename)
        formDataservice.append("pricing", price)
        formDataservice.append("isService", true)
        formDataservice.append("Category_id", serviceCategory_id)
        formDataservice.append("admin_id", admindetails._id)
        formDataservice.append("duration", duration)
        formDataservice.append("location", city)
        formDataservice.append("Date", date)
        formDataservice.append("Description", convertedContent)
        formDataservice.append("modle_id", modleId)
        formDataservice.append("fromdate", selectedFromDateTime)
        formDataservice.append("todate", selectedToDateTime)
        { profImg4?.length > 0 ? profImg4.forEach((item) => { formDataservice.append("profileimage", item) }) : formDataservice.append("profileimage", '') }
        { state4?.length > 0 ? state4.forEach((item) => { formDataservice.append("additionalimage", item) }) : formDataservice.append("additionalimage", '') }
        console.log([...formDataservice], "formDataserviceformDataservice");
        await axios.post(process.env.REACT_APP_BASE_URL + `services/addservice`, formDataservice, { headers: headerformdata })
            .then((res) => {
                console.log(res, "res");
                setShowAddModule(false)
                setButtonview1(false)
                // navigate('/talent')
                enqueueSnackbar("Added Successfully", { variant: 'success' })
            })
            .catch((error) => {
                setButtonview1(false)
                // setButtonview(false)
                console.log(error, "eeee in Service");
                enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })

            })
    }


    const handleFromDateTimeChange = (newValue) => {
        console.log(newValue);
        setSelectedFromDateTime(newValue);
        setError(prevError => ({ ...prevError, fromTime: '' }))
    };
    const handleToDateTimeChange = (newValue) => {
        setSelectedToDateTime(newValue);
        setError(prevError => ({ ...prevError, toTime: '' }))
    };


    const handleChangeDetails = (e, val) => {
        setaditnalValues(val)

    }

    const [fileTypes, setFileTypes] = React.useState([]);
    React.useEffect(() => {
        const fetchFileTypes = async () => {
            const types = await Promise.all(state1.map(async fileUrl => {
                const type = await getFileType(fileUrl);
                return type;
            }));
            setFileTypes(types);
        };

        fetchFileTypes();
    }, [state1]);

    const getFileType = async (fileUrl) => {
        try {
            const response = await axios.get(fileUrl, { responseType: 'blob' });
            console.log(response.data, "vresponseresponseresponse");
            return response.data.type;
        } catch (error) {
            return 'video/mp4';
        }
    }

    const TabChenge = () => {
        let NextValue = aditnalValues + 1
        setaditnalValues(NextValue)

    }

    const previesTab = () => {
        let preValue = aditnalValues - 1
        setaditnalValues(preValue)

    }

    // service  page 

    React.useEffect(() => {
        getServiceDetails()
    }, [rowsPerPage, page, search, status])

    const getServiceDetails = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `services/gettheservice?page=${page}&size=${rowsPerPage}&search=${search}&isService=${status == 'all' ? '' : status}`)
            .then((res) => {
                console.log(res.data, 'res.data');
                setServiceDetails(res.data.data)
                setTotal(res.data.totalcount)
            })
            .catch((error) => {
                console.log(error, "error");
            })
    }


    const EditDetails = () => {
        navigate('/editservice', { state: allDetails })
    }

    const handleModleOpen = () => {
        setShowAddModule(true)
    }
    const handleServiceModuleClose = () => {
        setShowAddModule(false)
    }
    const DeleteService = async () => {
        await axios.put(process.env.REACT_APP_BASE_URL + `services/deleteservice/${deleteid}`)
            .then((res) => {
                enqueueSnackbar("Deleted successfully", { variant: 'success' })
                getServiceDetails()
                handleClose()
            })
            .catch((error) => {
                console.log(error);
                enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
                // handleClose()
            })
    }

    const [expandedImageIndex, setExpandedImageIndex] = React.useState(-1); // State to track expanded image index

    const toggleImageExpand = (index) => {
        if (index === expandedImageIndex) {
            setExpandedImageIndex(-1); // Collapse if same image clicked again
        } else {
            setExpandedImageIndex(index); // Expand clicked image
        }
    };
    const renderImages = () => {
        if (addImg1.length === 0) {
            return (
                <Button className="multiple_upload_btn" variant="contained" component="label">
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                    </Box>
                    <input  accept="image/png, image/jpg, image/jpeg"
                        onChange={(e) => {AdditionalImgChange(e); e.target.value = null;}} hidden multiple type="file"/>
                </Button>
            );
        } else if (expandedImageIndex !== -1) {
            // Render expanded view with all images in a row
            return (
                <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                    {addImg1.map((image, index) => (
                        <Grid item key={index} xs={6} sm={4} md={4} lg={4}>
                            <Box className="uploaded_img" sx={{ position: 'relative' }}>
                                <Button className="close_icon" onClick={(e) => additionalDelete(e, index)} style={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}>
                                    <CloseIcon />
                                </Button>
                                {/* <img alt='' height={155} width={195} /> */}

                                <img src={image.slice(0, 4) === "blob" ? image : process.env.REACT_APP_BASE_URL + `${image}`}  alt="" height={155} width={195} style={{ display: 'block', cursor: 'pointer' }} onClick={() => toggleImageExpand(index)} />
                                {addImg1.length > 1 && (
                                    <FilterNoneIcon className="filter_none_icon" style={{ position: 'absolute', top: 8, left: 8, zIndex: 1 }} />
                                )}
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            );
        } else {
            // Render only the first image in a single view
            return (
                <Grid item xs={12}>
                    <Box className="uploaded_img" sx={{ position: 'relative' }}>
                        <Button className="close_icon" onClick={(e) => additionalDelete(e, 0)} style={{ position: 'absolute', top: 8, right: 8, zIndex: 1 }}>
                            <CloseIcon />
                        </Button>
                        <img src={addImg1.slice(0, 4) === "blob" ? addImg1[0] : process.env.REACT_APP_BASE_URL + `${addImg1[0]}`}  alt="" height={155} width={195} style={{ display: 'block', cursor: 'pointer' }} onClick={() => toggleImageExpand(0)} />
                        {addImg1.length > 1 && (
                            <FilterNoneIcon className="filter_none_icon" style={{ position: 'absolute', top: 8, left: 8, zIndex: 1 }} />
                        )}
                    </Box>
                </Grid>
            );
        }
    };
    // --------------------------------------------------------------------------------------------------
    return (
        <Box className='Addcompany'>
            <MiniDrawer type={7} />
            <Box component="section" className="contentWraper">

                <Box className=' whiteContainer'>
                    <Box className='content p-20'>
                        <Grid container rowSpacing={5} columnSpacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Typography component='h6' className='formTitle'>Add Model</Typography>
                                <Stepper alternativeLabel activeStep={viewpage} connector={<ColorlibConnector />}>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                                {viewpage == 1 ?
                                    <>
                                        <Grid container rowSpacing={2} columnSpacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Model Name <Typography component='span' className='star'>*</Typography></Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={modelname}
                                                        onChange={(e) => { setModelName(e.target.value); setError({ name: "" }) }}
                                                        placeholder='modle name'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                                {error.name && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.name}</p>}

                                            </Grid>
                                            {/* 
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>UserName <Typography component='span' className='star'>*</Typography></Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={username}
                                                        onChange={(e) => { setUserName(e.target.value); setError({ username: "" }) }}
                                                        placeholder='UserName'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                                {error.username && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.username}</p>}

                                            </Grid> */}

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Email <Typography component='span' className='star'>*</Typography></Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={email}
                                                        onChange={(e) => { setEmail(e.target.value); setError({ email: "" }) }}
                                                        placeholder='Email'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                                {error.email && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.email}</p>}

                                            </Grid>



                                            {/* <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'>Category <Typography component='span' className='star'>*</Typography></Typography>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        id="demo-select-small"
                                                        value={personName}
                                                        multiple
                                                        onChange={handleMultipleSelect}
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected) => {
                                                            if (personName.length === 0) {
                                                                return "No Category";
                                                            } else
                                                                if (personName.length === 1) {
                                                                    return personName[0].category_name;
                                                                } else {
                                                                    return `${personName.length} selected category`;
                                                                }
                                                        }}
                                                        MenuProps={MenuProps}
                                                        variant='outlined'
                                                        className='select'
                                                    >
                                                        {categoryDetails && categoryDetails.length > 0 ? categoryDetails.map((name) => (
                                                            <MenuItem key={name._id} value={name} >
                                                                <Checkbox checked={personName.some(data => data._id == name._id)} />
                                                                <ListItemText primary={name.category_name} />
                                                            </MenuItem>
                                                        )) : <MenuItem>No user found</MenuItem>}
                                                    </Select>
                                                </FormControl>
                                                {error.service && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.service}</p>}

                                            </Grid> */}



                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Location </Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        placeholder='Location'
                                                        value={location}
                                                        onChange={(e) => setLocation(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Model Height </Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={hight}
                                                        onChange={(e) => { setHight(e.target.value); }}
                                                        placeholder='Model Height'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>

                                            </Grid>

                                         

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Dress  </Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={dress}
                                                        onChange={(e) => { setDress(e.target.value); }}
                                                        placeholder='Dress'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,

                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Shoe </Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={shoe}
                                                        onChange={(e) => { setShoe(e.target.value); }}
                                                        placeholder='Shoe'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,

                                                        }}
                                                    />
                                                </FormControl>

                                            </Grid> <Grid item xs={12} sm={12} md={12} lg={12}>
                                                

                                            </Grid> <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'> Waist</Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={waist}
                                                        onChange={(e) => { setWaist(e.target.value); }}
                                                        placeholder='Waist size'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,

                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Hip</Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={hips}
                                                        onChange={(e) => { setHips(e.target.value); }}
                                                        placeholder='Hip size'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                            endAdornment: <InputAdornment position="end">cm</InputAdornment>,

                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Age</Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={age}
                                                        onChange={(e) => { setAge(e.target.value); }}
                                                        placeholder='age'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                            endAdornment: <InputAdornment position="end">years</InputAdornment>,

                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'>Skin Color </Typography>
                                                    <Select
                                                        value={color}
                                                        onChange={handleMenuItemSelect}
                                                        displayEmpty
                                                        className='dropdown-menu'
                                                        variant="outlined"
                                                        style={{ minWidth: '150px' }}
                                                    >
                                                        <MenuItem value='' disabled>Select skin color</MenuItem>
                                                        <MenuItem value='fair'> Fair</MenuItem>
                                                        <MenuItem value='light'>Light</MenuItem>
                                                        <MenuItem value='blond'>Blond</MenuItem>
                                                        <MenuItem value='brown'>Brown</MenuItem>
                                                        <MenuItem value='black'>black</MenuItem>
                                                        <MenuItem value='medium_black'>Medium black</MenuItem>
                                                        <MenuItem value='medium'>Medium</MenuItem>
                                                        <MenuItem value='medium'>Medium</MenuItem>

                                                    </Select>
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'> Body Type </Typography>
                                                    <Select
                                                        value={bodyType}
                                                        onChange={handleBodyTypeselect}
                                                        displayEmpty
                                                        className='dropdown-menu'
                                                        variant="outlined"
                                                        style={{ minWidth: '150px' }}
                                                    >
                                                        <MenuItem value='' disabled>Select Body Type</MenuItem>
                                                        <MenuItem value='endomorph'> Endomorph</MenuItem>
                                                        <MenuItem value='mesomorph'>Mesomorph</MenuItem>
                                                        <MenuItem value='ectomorph'>Ectomorph</MenuItem>
                                                        <MenuItem value='Athletic'>Athletic</MenuItem>
                                                        <MenuItem value='Thin'>Thin</MenuItem>
                                                        <MenuItem value='Chubby'>Chubby</MenuItem>
                                                        <MenuItem value='Gymmastic'>Gymmastic</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'> Hair Color</Typography>
                                                    <Select
                                                        value={hair}
                                                        onChange={handleHairColor}
                                                        displayEmpty
                                                        className='dropdown-menu'
                                                        variant="outlined"
                                                        style={{ minWidth: '150px' }}
                                                    >
                                                        <MenuItem value='' disabled>Select Hair Color</MenuItem>
                                                        <MenuItem value='black'>Black</MenuItem>
                                                        <MenuItem value='brown'>Brown</MenuItem>
                                                        <MenuItem value='blond'>Blond</MenuItem>
                                                        <MenuItem value='white'>White</MenuItem>
                                                        <MenuItem value='gray'>Gray</MenuItem>
                                                        <MenuItem value='red'>Red</MenuItem>
                                                        <MenuItem value='green'>Green</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'> Eye Type</Typography>
                                                    <Select
                                                        value={eyes}
                                                        onChange={handleEyeColoer}
                                                        displayEmpty
                                                        className='dropdown-menu'
                                                        variant="outlined"
                                                        style={{ minWidth: '150px' }}
                                                    >
                                                        <MenuItem value='' disabled>Select Hair Color</MenuItem>
                                                        <MenuItem value='amber'>Amber</MenuItem>
                                                        <MenuItem value='brown'>Brown</MenuItem>
                                                        <MenuItem value='hazel'>hazel</MenuItem>
                                                        <MenuItem value='red'>red</MenuItem>
                                                        <MenuItem value='gray'>Gray</MenuItem>
                                                        <MenuItem value='blue'>Blue</MenuItem>
                                                        <MenuItem value='green'>Green</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>



                                            <Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
                                                <Typography component='div' className='label'>Personal info </Typography>
                                                <div className="editorclass">
                                                    <Editor
                                                        editorState={editorState}
                                                        onEditorStateChange={handleEditorChange}
                                                        toolbarClassName="toolbar"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorcontent"

                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image'],
                                                            inline: {
                                                                options: ['bold', 'italic', 'underline'],
                                                            },
                                                            blockType: {
                                                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                            },
                                                            list: {
                                                                options: ['unordered', 'ordered'],
                                                            },
                                                            textAlign: {
                                                                options: ['left', 'center', 'right', 'justify'],
                                                            },
                                                            link: {
                                                                options: ['link'],
                                                            },
                                                            emoji: {
                                                                options: [
                                                                    '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
                                                                    '😍', '😋', '😎', '😴', '🤔', '😳', '🤗', '🤐', '😷', '🤒'
                                                                ],
                                                            },
                                                            image: {
                                                                uploadEnabled: true,
                                                                previewImage: true,
                                                                inputAccept: 'image/*',
                                                                alt: { present: false, mandatory: false },
                                                            },
                                                        }}

                                                    />


                                                </div>
                                            </Grid>

                                            <Grid container spacing={2}>
                                                {/* Profile Image Section */}
                                                <Grid item xs={12} md={6}>
                                                    <Typography component='div' className='label'>Profile Image</Typography>
                                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                        {profImg1.length < 1 &&
                                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                                                </Box>
                                                                <input accept={"image/png, image/jpg,image/jpeg"} onChange={profileImageChange} hidden type="file" />
                                                            </Button>
                                                        }
                                                    </Box>
                                                    {profImg1.length > 0 &&
                                                        <Grid container spacing={1} className="multiple_upload_container mb-10">
                                                            {profImg1.map((x, i) => (
                                                                <Grid item xs={12} md={12}>
                                                                    <Box className='uploaded_img'>
                                                                        <Button className='close_icon' onClick={(e) => DeleteProf(e, i)}><Close /></Button>
                                                                        <img src={x} alt='' height={155} width={195} />
                                                                    </Box>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    }
                                                </Grid>

                                                {/* Additional Images Section */}
                                                {/* <Grid item xs={12} md={6}>
                                                    <Typography component='div' className='label'>Cover Images</Typography>
                                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                        {state1.length < 1 &&
                                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                                                </Box>
                                                                <input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChange} hidden type="file" />
                                                            </Button>
                                                        }
                                                    </Box>
                                                    {state1.length > 0 &&
                                                        <Grid container spacing={1} className="multiple_upload_container mb-10">
                                                            {state1.map((x, i) => (
                                                                <Grid item xs={12} md={12}>
                                                                    <Box className='uploaded_img'>
                                                                        <Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
                                                                        <img src={x} alt='' height={155} width={195} />
                                                                    </Box>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    }
                                                </Grid> */}

                                                {/* Note Section */}
                                                <Grid item xs={12}>
                                                    <Typography className='fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
                                                </Grid>

                                                {/* Error Message Section */}
                                                {imageAddError && (
                                                    <Grid item xs={12}>
                                                        <p style={{ color: 'red' }} className="fz-13 m-0">{imageAddError}</p>
                                                    </Grid>
                                                )}

                                        <Box style={{ marginTop: 10 }} className='heading-btns'>
                                            <Button onClick={handleCardDetailes} variant="contained" className='addbtn mr-10'>Add Bank Details </Button>
                                        </Box>
                                        {cardOpen && (
                                            <div className="modal">
                                                  <Card>           
                                                <div className="modal-content">
                                                    <form onSubmit={CreateService} style={{
                                                        backgroundColor: '#f0f0f0',
                                                        borderRadius: '10px',
                                                        padding: '20px',
                                                        width: '300px',
                                                        margin: '0 auto',
                                                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)'
                                                    }}>
                                                       <CardElement/>
                                                    </form>
                                                </div>
                                    
                                                </Card>
                                            </div>
                                        )}

                                            </Grid>

                                        </Grid>
                                    </>
                                    :

                                    // 22222222222
                                    <>

                                        <Box style={{ display: 'flex ', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <Box className='flex-box mb-10 segmentControl' style={{ justifyContent: 'start' }}>
                                                <Tabs
                                                    value={aditnalValues}
                                                    onChange={handleChangeDetails}
                                                    textColor="secondary"
                                                    indicatorColor="secondary"
                                                    aria-label="secondary tabs example"
                                                    className='segmentControl'
                                                    variant="fullWidth" // Add this line to make tabs full width
                                                    sx={{ '& .MuiTabs-indicator': { display: 'none' } }}
                                                >
                                                    {/* <Tab className='subsegment' sx={{ textTransform: "none" }} value={1} label="Images" /> */}
                                                    <Tab className='subsegment' sx={{ textTransform: "none" }} value={2} label="Video" />
                                                </Tabs>
                                            </Box>
                                        </Box>


                                        <Grid item xs={12} sm={12} md={12}>

                                            {/* {aditnalValues === 1 &&
                                                <>
                                                     <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <Typography component="div" className="label">Additional Images</Typography>
                                                            <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                                {renderImages()}
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography className="fz-12 mt-10 mb-10" component="p" variant="p">
                                                                <strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>

                                        </>

                                        } */}



                            {/* {aditnalValues === 2 &&
                                         <Grid container spacing={2} alignItems="center">
                                            
                                                    <Grid item xs={12}>
                                                        <Typography component='div' className='label'>Additional Videos</Typography>
                                                        <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                            {video1.length < 1 &&
                                                                <Button className="multiple_upload_btn" variant="contained" component="label">
                                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><VideocamIcon className="fz-18 mr-5" /> Upload</Box>
                                                                    <input accept={"video/mp4"} onChange={AdditionalVideoChange} hidden  type="file" />
                                                                </Button>
                                                            }
                                                        </Box>
                                                    </Grid>
                                                    {video1.length > 0 &&
                                                        <Grid item xs={12}>
                                                            <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                                {video1.map((x, i) => (
                                                                    <Grid item key={i} xs={6} sm={4} md={4} lg={4}>
                                                                        <Box className='uploaded_img' >
                                                                            <video src={x} alt='' height={155} width={195} />
                                                                            <Button className='close_icon' onClick={(e) => indexVideoDelete(e, i)}><Close /></Button>
                                                                        </Box>
                                                                    </Grid>
                                                                ))}
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                </Grid>
                                            } */}

                                    {aditnalValues === 2 &&
                                            <Grid container spacing={2} alignItems="center">
                                                <Grid item xs={12}>
                                                    <Typography component='div' className='label'>Additional Media</Typography>
                                                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                        {video11.length < 1 && images11.length < 1 &&
                                                            <Button className="multiple_upload_btn" variant="contained" component="label">
                                                                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                                    <VideocamIcon className="fz-18 mr-5" /> Upload
                                                                </Box>
                                                                <input accept="video/mp4,image/*" onChange={AdditionalVideoChange} hidden type="file" multiple />
                                                            </Button>
                                                        }
                                                    </Box>
                                                </Grid>
                                                {(video11.length > 0 || images11.length > 0) &&
                                                    <Grid item xs={12}>
                                                        <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                            {video11.map((x, i) => (
                                                                <Grid item key={i} xs={6} sm={4} md={4} lg={4}>
                                                                    <Box className='uploaded_img'>
                                                                        <video src={URL.createObjectURL(x)} alt='' height={155} width={195} controls />
                                                                        <Button className='close_icon' onClick={(e) => indexVideoDelete12(e, i)}><Close /></Button>
                                                                    </Box>
                                                                </Grid>
                                                            ))}
                                                            {images11.map((x, i) => (
                                                                <Grid item key={i} xs={6} sm={4} md={4} lg={4}>
                                                                    <Box className='uploaded_img'>
                                                                        <img src={URL.createObjectURL(x)} alt='' height={155} width={195} />
                                                                        <Button className='close_icon' onClick={(e) => indexImageDelete(e, i)}><Close /></Button>
                                                                    </Box>
                                                                </Grid>
                                                            ))}
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                            }
                                            {/* ------------------------------------------------------------------------------------------------------------------------------------ */}



                                        </Grid>


                                    </>
                                }

                            </Grid>
                        </Grid>

                        {viewpage == 1 ?
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                {buttonview == false ?
                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                        <Button onClick={CreateService} variant="contained" className='addbtn mr-10' >Submit</Button>
                                    </Box>
                                    :
                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                        <Button variant="contained" className='addbtn mr-10' >Please Wait...</Button>
                                    </Box>

                                }
                                {buttonview == false ?
                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                        <Button onClick={SubmitContinue} variant="contained" className='addbtn mr-10' >Submit and Continue</Button>
                                    </Box>
                                    :
                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                        <Button variant="contained" className='addbtn mr-10' >Please Wait...</Button>
                                    </Box>
                                }
                            </Box>
                            :

                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                <Box style={{ marginTop: 10 }} className='heading-btns'>

                                    {aditnalValues > 1 && <Button onClick={previesTab} variant="contained" className='addbtn mr-10' >Back</Button>}
                                    {aditnalValues < 2 && <Button onClick={TabChenge} variant="contained" className='addbtn mr-10' >Next</Button>}


                                </Box>


                                {buttonview1 == false ?
                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                        {aditnalValues > 1 && <Button onClick={handleUplode} variant="contained" className='addbtn mr-10' >Submit</Button>}
                                    </Box>
                                    :
                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                        <Button variant="contained" className='addbtn mr-10' >Please Wait...</Button>
                                    </Box>}
                            </Box>
                        }
                    </Box>

                </Box>

            </Box>
        </Box>
    )
}

const PaymentForm = () => (
    <Elements stripe={stripePromise}>
        <AddModels />
    </Elements>
);

export default PaymentForm;
