import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SearchOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import axios from 'axios'
import { useSnackbar, SnackbarProvider } from 'notistack'

export default function AddCategory() {
    const { enqueueSnackbar } = useSnackbar()
    const headerformdata = {
		'Content-Type': 'multipart/form-data',
		
	}
	const [existingName , setExistingName]  = React.useState('')
    const [buttonview, setButtonview] = React.useState(false) 

    const navigate = useNavigate()
    const [age, setAge] = React.useState('');
    const [imageAddError, setImageAddError] = React.useState('')
	const [state1, setState1] = React.useState([])
	const [state2, setState2] = React.useState([])
    const [ name ,setName]  = React.useState('')
    const [ ShortDescription ,setShortDescription] = React.useState('')
    const [statesingle, setStatesingle] = React.useState([])
	const [statesingle2, setStatesingle2] = React.useState([])

    const [ error, setErrors] = React.useState('')


// cover image
    const multipleImgChange = (e, index) => {
		
		var fileObj = [];
		var fileArray = [];
		var filepath = [];

		fileObj.push(e.target.files)
		for (let i = 0; i <= fileObj[0].length; i++) {
			if (i < fileObj[0].length) {
				fileArray.push(URL.createObjectURL(fileObj[0][i]))
				filepath.push(fileObj[0][i])
			}
		}
		setState1(prevValue => prevValue.concat(fileArray))
		setState2(prevValue => prevValue.concat(filepath))
	}

	const indexDelete = (e, index) => {
		var filteredpreview = state1.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = state2.filter((val, i) => {
			return i !== index;
		});
		setState1(filteredpreview);
		setState2(filteredraw);
	}

 // validation
 const Validation = () => {
   
    if(!name){
        setErrors("This field is required");
			return false
    }
    else if (!name){
        setErrors("This field is required");
        return false
    }
	
    return true
}
    
const handleBack = () =>{
    navigate('/category')
}
    const handleSubmit = async() => {
        let validate = Validation()      
        if(validate){
         setButtonview(true)
        const formData = new FormData();
        formData.append("category_name", name)
        formData.append("short_desc",ShortDescription )
        console.log(state2,"sttee");
        { state2 ? state2.forEach((item) => { formData.append("photo", item) }) : formData.append("photo", '') }
        await axios.post(process.env.REACT_APP_BASE_URL + `admincategory/addcategory`, formData, { headers: headerformdata }).then((res) => {
			setButtonview(false)
			enqueueSnackbar("Category added Successfully", { variant: 'success' })
			navigate('/category');
        }).catch((error) => {
			setButtonview(false)
			 let errors =error.response.data.message
			 if(errors == "Category name already exist!") {
				setExistingName("Category name already exist!")
				enqueueSnackbar("Category name already exist!",{ variant: 'error' })
			 }
			 else{
				console.log(error, "error");
				enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
			 }
        })
    }
}
    return (
        <Box className='Addcompany'>
            <MiniDrawer type={1}/>
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                             <Typography component='h6' className='formTitle'>Add Category</Typography>
                       <Grid container rowSpacing={2} columnSpacing={2}>                                
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'> Title <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Title'
                                                onChange={(e) => {setName(e.target.value);setExistingName('');setErrors('')}}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                          {error && <p className="error-message"  style={{color:"red"}}>{error}</p>} 
                                          {existingName && <p className="error-message"  style={{color:"red"}}>{existingName}</p>} 
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Short Description</Typography>
                                            <textarea
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textarea'
                                                placeholder='Short Description'
                                                onChange={(e) =>setShortDescription(e.target.value) }
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                 
                                    <Grid item xs={10} sm={10} md={10}>
									<label className='form-labels ' for="my-input" >Primary Image </label>
									<Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
										{state1.length < 1 &&

											<Button className="multiple_upload_btn" variant="contained" component="label">
												<Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
												<input accept={"image/png, image/jpg,image/jpeg"} onChange={multipleImgChange} hidden type="file" />
											</Button>
										}
									</Box>
								</Grid>

								<Grid item xs={10} sm={10} md={10}  >
									{state1.length > 0 &&
										<Grid container columnSpacing={1} rowSpacing={1}          className="multiple_upload_container mb-10">
											 {state1.length > 0 ? state1.map((x, i) => (
												<Grid item xs={6} sm={4} md={4} lg={4} >
													<Box className='uploaded_img' >
														<Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
														<img src={x} alt='' height={155} width={195} />
													</Box>
												</Grid>
											)) : ""}
										</Grid>
									}
								</Grid>
                                    
								<Grid item xs={12} sm={12} md={12} lg={12}>
									<Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
								</Grid> 
                                    
                                </Grid>

								{buttonview == false ?
                                <Box className='heading-btns mt-20' style={{textAlign:'end'}}>
                                    <Button  onClick={handleBack} variant="contained" className='addbtn mr-10' >Back</Button>
                                    <Button variant="contained" className='addbtn mr-10' onClick={handleSubmit}>Submit</Button>
                                </Box>
								:
								<Box className='heading-btns mt-20' style={{textAlign:'end'}}>
								<Button variant="contained" className='addbtn mr-10'>Please Wait...</Button>
							</Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}
