import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography } from '@mui/material'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SearchOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import axios from 'axios'
import { useSnackbar, SnackbarProvider } from 'notistack'

export default function AddCategory() {
    const { enqueueSnackbar } = useSnackbar()
    const headerformdata = {
		'Content-Type': 'multipart/form-data',
		
	}
    let admindetails = JSON.parse(localStorage.getItem("userData"))
	const [existingName , setExistingName]  = React.useState('')
    const [buttonview, setButtonview] = React.useState(false) 

    const [categoryDetails, setCategoryDetails] = React.useState([])
    const [serviceDetailes, setServiceDetailes] = React.useState([])
    const [serviceImageproError, setServiceImageproError] = React.useState('')
    const [imageproError, setImageproError] = React.useState('')
    const [talentName, setTalentName] = React.useState('')

    const [email, setEmail] = React.useState('')
     const [category, setCategory] = React.useState('')
     const [location, setLocation] = React.useState('')
    const [error, setError] = React.useState({ name: "", service: "", email: "", Category: "" })
    const navigate = useNavigate()
    const [age, setAge] = React.useState('');
    const [imageAddError, setImageAddError] = React.useState('')
	const [state1, setState1] = React.useState([])
	const [state2, setState2] = React.useState([])
    const [profImg1, setProfImg1] = React.useState([])
    const [profImg2, setProfImg2] = React.useState([])
    const [ name ,setName]  = React.useState('')
    const [ ShortDescription ,setShortDescription] = React.useState('')
    const [statesingle, setStatesingle] = React.useState([])
	const [statesingle2, setStatesingle2] = React.useState([])



// cover image
    const multipleImgChange = (e, index) => {
		
		var fileObj = [];
		var fileArray = [];
		var filepath = [];

		fileObj.push(e.target.files)
		for (let i = 0; i <= fileObj[0].length; i++) {
			if (i < fileObj[0].length) {
				fileArray.push(URL.createObjectURL(fileObj[0][i]))
				filepath.push(fileObj[0][i])
			}
		}
		setState1(prevValue => prevValue.concat(fileArray))
		setState2(prevValue => prevValue.concat(filepath))
	}

	const indexDelete = (e, index) => {
		var filteredpreview = state1.filter((value, i) => {
			return i !== index;
		});
		var filteredraw = state2.filter((val, i) => {
			return i !== index;
		});
		setState1(filteredpreview);
		setState2(filteredraw);
	}

const profileImageChange = (e, index) => {
    setImageproError('')
    var fileObj = [];
    var fileArray = [];
    var filepath = [];

    fileObj.push(e.target.files)
    for (let i = 0; i <= fileObj[0].length; i++) {
        if (i < fileObj[0].length) {
            fileArray.push(URL.createObjectURL(fileObj[0][i]))
            filepath.push(fileObj[0][i])
        }
    }
    setProfImg1(prevValue => prevValue.concat(fileArray))
    setProfImg2(prevValue => prevValue.concat(filepath))
}

const DeleteProf = (e, index) => {
    var filteredpreview = profImg1.filter((value, i) => {
        return i !== index;
    });
    var filteredraw = profImg2.filter((val, i) => {
        return i !== index;
    });
    setProfImg1(filteredpreview);
    setProfImg2(filteredraw);
}

 // validation
 const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

 const Validation = () => {
     if (!name&&!email) {
         setError({ name: "This field is required.",   email: "This field is required." })
         return false
     }
   
     if (!email) {
         setError({ email: "This field is required." })
         return false
     }
     if (email) {
         if (!emailRegex.test(String(email).toLowerCase())) {
             setError({ email: "Please enter valid email address" })
             return false
         }
     }
     return true
 }
 const handleBack = () =>{
    navigate('/customer')
}
        const handleSubmit = async () => {
            let valid = Validation()
            if (valid) {
                setButtonview(true)
    
                const formData = new FormData();
                formData.append("name",name )
                formData.append("isAdmin", false)
                formData.append("isStatus", true)
                formData.append("isService", false)
                formData.append("admin_id", admindetails._id)
                formData.append("location", location)
                formData.append("email", email)
                formData.append("city", '')
                formData.append("state", '')
                formData.append("pin", '') 
                formData.append("role", 'User')
                formData.append("isOnline", false)
                formData.append("address", '')
                { profImg2?.length > 0 ? profImg2.forEach((item) => { formData.append("profile", item) }) : formData.append("profile", '') }
                { state2?.length > 0 ? state2.forEach((item) => { formData.append("converimage", item) }) : formData.append("converimage", '') }
                console.log([...formData], "4444");
                await axios.post(process.env.REACT_APP_BASE_URL + `talents/addtalentsdeta`, formData, { headers: headerformdata })
                    .then((res) => {
                        console.log(res, "resuser");
                        setButtonview(false)
                        navigate('/customer')
                        enqueueSnackbar("Added Successfully", { variant: 'success' })
                    })
                    .catch((error) => {
                        setButtonview(false)
                        console.log(error, "eeee");
                        enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })
    
                    })
            }
    
        }
    return (
        <Box className='Addcompany'>
            <MiniDrawer type={3}/>
            <Box component="section" className="contentWraper">
                <Grid container rowSpacing={5} columnSpacing={3}>
                    <Grid item xs={12} sm={12} md={8} lg={6} xl={5}>
                        <Box className=' whiteContainer'>
                            <Box className='content p-20'>
                                <Typography component='h6' className='formTitle'>Add Customer</Typography>
                                <Grid container rowSpacing={2} columnSpacing={2}>                                
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                        <Typography component='div' className='label'> Name <Typography component='span' className='star'>*</Typography></Typography>
                                            <TextField id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textfield'
                                                placeholder='Name'
                                                onChange={(e) => {setName(e.target.value);setExistingName('');setError('')}}
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                          {error.name && <p className="error-message"  style={{color:"red"}}>{error.name}</p>} 
                                          {existingName && <p className="error-message"  style={{color:"red"}}>{existingName}</p>} 

										  
                                        </FormControl>
                                    </Grid>
                             
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Email <Typography component='span' className='star'>*</Typography></Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={email}
                                                        onChange={(e) => { setEmail(e.target.value); setError({ email: "" }) }}
                                                        placeholder='Email'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                                {error.email && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.email}</p>}

                                            </Grid>
                                   
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <FormControl variant="standard" fullWidth>
                                            <Typography component='div' className='label'>Short Description</Typography>
                                            <textarea
                                                id="filled-size-small"
                                                variant="outlined"
                                                size="small"
                                                className='textarea'
                                                placeholder='Short Description'
                                                onChange={(e) =>setShortDescription(e.target.value) }
                                                InputProps={{
                                                    startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                }}
                                            />
                                        </FormControl>
                                    </Grid>
                                 
                          
                                       <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Location </Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        placeholder='Location'
                                                        value={location}
                                                        onChange={(e) => setLocation(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            </Grid>

                                <Grid container spacing={2}>
                                    {/* Profile Image Section */}
                                    <Grid item xs={12} md={6}>
                                        <Typography component='div' className='label'>Profile Image</Typography>
                                        <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                            {profImg1.length < 1 &&
                                                <Button className="multiple_upload_btn" variant="contained" component="label">
                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload
                                                    </Box>
                                                    <input accept={"image/png, image/jpg,image/jpeg"} onChange={profileImageChange} hidden type="file" />
                                                </Button>
                                            }
                                        </Box>
                                        {profImg1.length > 0 &&
                                            <Grid container spacing={1} className="multiple_upload_container mb-10">
                                                {profImg1.map((x, i) => (
                                                    <Grid item xs={12} md={12}>
                                                        <Box className='uploaded_img'>
                                                            <Button className='close_icon' onClick={(e) => DeleteProf(e, i)}><Close /></Button>
                                                            <img src={x} alt='' height={155} width={195} />
                                                        </Box>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography className='fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
                                    </Grid>
                                </Grid>
								{buttonview == false ?
                                <Box className='heading-btns mt-20' style={{textAlign:'end'}}>
                            <Button variant="contained" className='addbtn mr-10' onClick={handleBack}>Back</Button>

                                    <Button variant="contained" className='addbtn mr-10' onClick={handleSubmit}>Submit</Button>
                                </Box>
								:
								<Box className='heading-btns mt-20' style={{textAlign:'end'}}>
								<Button variant="contained" className='addbtn mr-10'>Please Wait...</Button>
							</Box>}
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Box >
        </Box >
    )
}
