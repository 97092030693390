import React from 'react'
import MiniDrawer from '../../../components/drawer'
import { PlaylistAddRounded, SearchOutlined } from '@mui/icons-material'
import Table from '@mui/material/Table';
import Select from '@mui/material/Select';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Box, Button, Typography, ClickAwayListener, FormControl, Avatar, Grow, InputAdornment, MenuItem, Menu, MenuList, Popper, TextField, Grid, colors } from '@mui/material';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios'
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import DOMPurify from 'dompurify';
import Badge from '@mui/material/Badge';
import moment from "moment";
import PersonPinIcon from '@mui/icons-material/PersonPin';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-modal';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Rating from '@mui/material/Rating';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CancelIcon from '@mui/icons-material/Cancel';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';
import { useNavigate } from 'react-router-dom';
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
// import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
// import {  LocalizationProvider } from '@mui/x-date-pickers'
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Navigate } from 'react-router-dom';
import Checkout from './checkout';

const localizer = momentLocalizer(moment);
export default function Booking() {
    const [users, setUsers] = React.useState([]);
    console.log(users,'usersusers');
    const [newdata, setNewdata] = React.useState([])
    const [page, setPage] = React.useState(0);
    const [total, setTotal] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [calenderData, setCalenderData] = React.useState([]);
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    const [calenderOpen, setCalenderOpen] = React.useState(false);
    const [search, setSearch] = React.useState("");
    const [status, setStatus] = React.useState("all");
    const [showFilters, setShowFilters] =  React.useState(false);
    const [fromDate, setFromDate] = React.useState(null);
    const [toDate, setToDate] =  React.useState(null);
    const [selectedTalent, setSelectedTalent] = React.useState(null);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [bookingStatus, setBookingStatus] = React.useState({status:''});
    const [bookingid, setBookingid] = React.useState('');
    const [talentsid, setTalentsid] = React.useState('');
    const [adminids, setAdminids] = React.useState('');
    let navigate = useNavigate()

    
    const toggleFilters = () => {
        setShowFilters(prev => !prev);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    React.useEffect(() => {
        getBookings()
    }, [page, rowsPerPage])


    const handlePayButtonClick = (row) => {
        setSelectedTalent(row);
        setIsModalOpen(true);
        navigate('/checkout')
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
        setSelectedTalent(null);
      };

      const handleStatusChange = (event, rowId, row) => {
        setBookingid(rowId)
        setTalentsid(row.user_id._id)
        setAdminids(row.user_id.admin_id)
        setBookingStatus((prevStatus) => ({
          ...prevStatus,
          [rowId]: event.target.value,
        }));
      };

      let body = {
        adminId:adminids,
        talentId:talentsid 
      }

      const approvedbooking = async() =>{
        await axios.post(process.env.REACT_APP_BASE_URL + `adminpart/approvebookings/${bookingid}`,body)
        .then((res)=>{
            console.log(res,"response from approved api")
        }).catch((error)=>{
            console.log(error,"error in approved api")
        })
      }
    //   approvedbooking()

    const getBookings = async () => {
        try {
            const baseUrl = process.env.REACT_APP_BASE_URL + `paymentsdata/getthebookings?page=${page}&size=${rowsPerPage}&search=${search}`;
          
            const params = {};
            console.log(baseUrl,"baseUrlbaseUrl");
            if (fromDate) params.fromdate = fromDate.format("YYYY-MM-DD");
            if (toDate) params.todate = toDate.format("YYYY-MM-DD");
            const response = await axios.get(baseUrl, { params });
            console.log(response.data, "response from booking");
            setCalenderData(response.data.data);
            setUsers(response.data.data);
            setTotal(response.data.totalcount);
        } catch (err) {
            console.log(err, "error in booking");
        }
    };
    const resetBooking = () =>{
        setShowFilters(false)
        setFromDate(null)
        setToDate(null)
        getBookings()
    }

    const handleRemove = () => {
        setStatus("all")
        setSearch('')
    }
    const handleSelectEvent = (event) => {
        setSelectedEvent(event);
    };
    const events = calenderData.map((caldata) => ({
        id: caldata._id,
        title: caldata.Bookingname,
        userName: caldata.serviceID ? caldata.serviceID.name : "-",
        start: new Date(caldata.start.dateTime),
        end: new Date(caldata.end.dateTime),
        email: caldata.email,
        Description: caldata.description,
        summary: caldata.summary,
        location: caldata.location,


    }));

    const handleSearchChange = (e) => {
        setSearch(e.target.value);
        setPage(0);
    };

    const handleCancelClick = () => {
        setSearch('');

    };
    const viewBookings = () => {
       navigate('/viewbooking')
    }
    const closeCalender = () => {
        setCalenderOpen(false)
    }





    const handleFromDateTimeChange = (newValue) => {
        console.log(newValue);
        setSelectedFromDateTime(newValue);
    };
    const handleToDateTimeChange = (newValue) => {
        setSelectedToDateTime(newValue);
    };


    return (
        <Box className='companies'>
            <MiniDrawer type={5} />
            <Box component="section" className="contentWraper">
                <Box className='headingBox'>
                    <Typography component='h4' variant='h4' className='page-heading' >Booking</Typography>
                    <Button variant="contained" className='addbtn'onClick={(e) => viewBookings()}  style={{ marginTop: '10px' }}> View Bookings</Button>
                </Box>
                <Grid container rowSpacing={2} columnSpacing={2} style={{ marginBottom: 10, alignItems: 'center' }}>
    <Grid item xs={12} sm={6} md={4} lg={3}>
        <FormControl variant="outlined" fullWidth size="small">
            <TextField
                id="filled-size-small"
                variant="outlined"
                size="small"
                value={search}
                onChange={handleSearchChange}
                placeholder="Search Here..."
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchOutlinedIcon className="fz-18" />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {search && (
                                <IconButton onClick={handleCancelClick}>
                                    <CancelIcon className="fz-18" />
                                </IconButton>
                            )}
                        </InputAdornment>
                    ),
                }}
            />
        </FormControl>
    </Grid>
    <Grid item xs={12} sm={6} md={8} lg={9}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
            <Button onClick={toggleFilters}  style={{ display: 'flex', alignItems: 'center', color: 'black', fontWeight:'bold' }}>
                {showFilters ? '' : 'Filter'}
            </Button>
            {showFilters && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Grid container spacing={2} style={{ alignItems: 'center' }}>
                        <Grid item xs={5} sm={4} md={5} lg={4}>
                            <Typography component="div" className="label"><Box fontWeight='bold' display='inline'>From</Box></Typography>
                            <FormControl variant="standard" fullWidth className="datepicker">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="from"
                                        value={fromDate}
                                        onChange={(newValue) => setFromDate(newValue)}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                        <Grid item xs={5} sm={4} md={5} lg={4}>
                            <Typography component="div" className="label"><Box fontWeight='bold' display='inline'>To</Box></Typography>
                            <FormControl variant="standard" fullWidth className="datepicker">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        label="to"
                                        value={toDate}
                                        onChange={(newValue) => setToDate(newValue)}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                        </Grid>
                    </Grid>
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginLeft: '10px' }}>
                        {fromDate != null && toDate != null && (
                            <Button variant="contained" className="addbtn" onClick={getBookings} style={{ marginRight: '5px' }}>Apply</Button>
                        )}
                        {(
                            <Button variant="contained" className="addbtn" onClick={resetBooking}>Reset</Button>
                        )}
                    </div>
                </div>
            )}
        </div>
    </Grid>
</Grid>


                <Box>
                    <TableContainer component={Paper} id='table'>
                        <Table sx={{ minWidth: 700 }} aria-label="customized table" className=''>
                            <TableHead className='DT-head'>
                                <TableRow className=''>

                                    <TableCell align="left" className='TRow DT-checkbox' style={{ width: 100, padding: '6px 16px', fontWeight:'bold' }}>Service Name</TableCell>
                                    <TableCell align="left" className='TRow DT-checkbox' style={{ width: 100, padding: '6px 16px', fontWeight:'bold' }}>Talent Name</TableCell>
                                    <TableCell align="left" className='TRow DT-checkbox' style={{ width: 200, padding: '6px 16px', fontWeight:'bold' }}>Email</TableCell>
                                    <TableCell align="left" className='TRow DT-checkbox' style={{ width: 200, padding: '6px 16px', fontWeight:'bold' }}>Location</TableCell>
                                    <TableCell align="left" className='TRow DT-checkbox' style={{ width: 100, padding: '6px 16px', fontWeight:'bold' }}>Payment Status</TableCell>
                                    <TableCell align="left" className='TRow DT-checkbox' style={{ width: 200, padding: '6px 16px', fontWeight:'bold' }}> From Date</TableCell>
                                    <TableCell align="left" className='TRow DT-checkbox' style={{ width: 100, padding: '6px 16px', fontWeight:'bold' }}> To Date</TableCell>
                                    <TableCell align="left" className='TRow DT-checkbox' style={{ width: 100, padding: '6px 16px', fontWeight:'bold' }}> Service Status</TableCell>
                                    <TableCell align="left" className='TRow DT-checkbox' style={{ width: 100, padding: '6px 16px', fontWeight:'bold' }}> Pay for Talents</TableCell>
                                    <TableCell align="left" className='TRow DT-checkbox' style={{ width: 100, padding: '6px 16px', fontWeight:'bold' }}>Booking Approved</TableCell>

                                    {/* <TableCell align="left" className='TRow DT-checkbox' style={{ minWidth: 200, padding: '6px 16px' }}>Action </TableCell> */}


                                </TableRow>
                            </TableHead>
                            <TableBody className="characters ">
                                {users && users?.length > 0 ? users.map((row, i) => (
                                    <>
                                        <TableRow className=''>

                                            <TableCell className='DT-row' align="left" style={{ minWidth: 20, maxWidth: 20 }}>{row && row.Bookingname}</TableCell >
                                            <TableCell className='DT-row' align="left" style={{ minWidth: 20, maxWidth: 20 }}>{row && row.user_id ? row.user_id.name : '-'}</TableCell >
                                            <TableCell className='DT-row' align="left" style={{ minWidth: 200, maxWidth: 250 }}>{row && row.email}</TableCell >
                                            <TableCell className='DT-row' align="left" style={{ minWidth: 20, maxWidth: 20 }}>{row && row.city}</TableCell >
                                            <TableCell className='DT-row' align="left" style={{ minWidth: 20, maxWidth: 20 }}>{row && row.status == "complete" ? <strong style={{ background: "green", padding: "5px" }}>Complete</strong> : <strong style={{ background: "#ffb366", padding: "5px",paddingLeft:"12px" }}>Pending</strong>}</TableCell >
                                            <TableCell className='DT-row' align="left" style={{ minWidth: 20, maxWidth: 20 }}>  {moment(row && row.start_date).format("DD MMM YYYY h:mm a")} </TableCell >
                                            <TableCell className='DT-row' align="left" style={{ minWidth: 20, maxWidth: 20 }}>  {moment(row && row.end_date).format("DD MMM YYYY h:mm a")}</TableCell >
                                            {/* service_complete */}
                                            <TableCell className='DT-row' align="left" style={{ minWidth: 20, maxWidth: 20 }}>{row && row.service_complete == "completed" ? <strong style={{ background: "green", padding: "5px" }}>Complete</strong> : <strong style={{ background: "#ffb366", padding: "5px",paddingLeft:"12px" }}>Pending</strong>}</TableCell >
                                            <TableCell align="left" className='DT-row' style={{ minWidth: 100, maxWidth: 100 }}>
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={() => handlePayButtonClick(row)}
                                                    disabled={bookingStatus[row._id] !== 'approved'}
                                                >
                                                    Pay
                                                </Button>
                                            </TableCell>
                                            <TableCell>
                                                {bookingStatus[row._id] !== 'approved' ? (
                                                    <Select
                                                        value={bookingStatus[row._id] || ''}
                                                        onChange={(event) => handleStatusChange(event, row._id,row)}
                                                        displayEmpty
                                                        inputProps={{ 'aria-label': 'Booking Status' }}
                                                    >
                                                        <MenuItem value="">Select Status</MenuItem>
                                                        <MenuItem value="approved">Approved</MenuItem>
                                                        <MenuItem value="rejected">Rejected</MenuItem>
                                                    </Select>
                                                ) : (
                                                        <Button variant="contained" disabled sx={{background:'green', color:'white'}}> 
                                                            Approved
                                                        </Button>
                                                )}
                                            </TableCell>
                                        </TableRow>
                                    </>

                                )) : <TableRow className=''>
                                    <TableCell className='DT-row' align="center" colSpan={5}>
                                        No Data Found
                                    </TableCell >
                                </TableRow>}
                            </TableBody>

                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15, 20, 25]}
                        component="div"
                        count={total}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className='DT-pagination'
                    />


                    <div style={{ marginLeft: '400px', marginTop: '100px' }}>
                        <Modal
                            isOpen={calenderOpen}
                            onRequestClose={closeCalender}
                            contentLabel="View Bookings Modal"
                        >
                            <div style={{ display: 'grid', gridTemplateColumns: '70% 40%', gap: '20px', marginLeft: '200px', marginTop: '100px' }}>
                                <div className="calendar-container">
                                    <div className="calendar">
                                    <Button variant="contained" className='addbtn mr-10' onClick={closeCalender}>Back</Button>

                                      
                                        <Calendar
                                            localizer={localizer}
                                            events={events}
                                            startAccessor="start"
                                            endAccessor="end"
                                            onSelectEvent={handleSelectEvent}
                                            style={{ height: 500 }}
                                        />
                                    </div>
                                </div>
                                {selectedEvent && (
                                    <div className="event-details">
                                        <h2>Event Details</h2>
                                        <p><strong>Title:</strong> {selectedEvent.title}</p>
                                        <p><strong>Name:</strong> {selectedEvent.userName}</p>
                                        <p><strong>Start:</strong> {selectedEvent.start.toString()}</p>
                                        <p><strong>End:</strong> {selectedEvent.end.toString()}</p>
                                        <p><strong>Description:</strong> {selectedEvent.Description}</p>
                                        <p><strong>Email:</strong> {selectedEvent.email}</p>
                                        <p><strong>Location:</strong> {selectedEvent.location}</p>
                                    </div>
                                )}
                            </div>

                            <button onClick={closeCalender}>Close</button>
                        </Modal>
                    </div>


                </Box>
            </Box>
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                style={{ content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)' } }}
            >
                <button onClick={closeModal} style={{ float: 'right' }}>Close</button>
                {selectedTalent && <Checkout talent={selectedTalent} onClose={closeModal} />}
            </Modal>
        </Box>
      
        
    )
}
