import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Input, OutlinedInput, Checkbox, ListItemText } from '@mui/material'
import React from 'react'
import MiniDrawer from '../../../components/drawer'
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { SearchOutlined } from '@mui/icons-material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Close, Dvr, ListAlt } from '@mui/icons-material';
import axios from 'axios'
import { useSnackbar, SnackbarProvider } from 'notistack'
import VideocamIcon from '@mui/icons-material/Videocam';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Editor } from 'react-draft-wysiwyg';
import { stateToHTML } from 'draft-js-export-html';
import { EditorState } from 'draft-js';
import { stateFromHTML } from 'draft-js-import-html';
import dayjs from 'dayjs';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useNavigate, useLocation } from 'react-router-dom';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export default function EditService() {

    let locationdata = useLocation()

    console.log(locationdata.state, "locationdatalocationdata");
    const [value, setValue] = React.useState(dayjs(locationdata.state.Date));
    let admindetails = JSON.parse(localStorage.getItem("userData"))

    let navigate = useNavigate()
    const { enqueueSnackbar } = useSnackbar()
    const [imageAddError, setImageAddError] = React.useState('')
    const [imageproError, setImageproError] = React.useState('')
    const [state1, setState1] = React.useState(locationdata.state.additionalimage ? locationdata.state.additionalimage : [])
    const [state2, setState2] = React.useState(locationdata.state.additionalimage ? locationdata.state.additionalimage : [])

    const [existimg, setExistimg] = React.useState(locationdata.state.additionalimage)
    const [profImg1, setProfImg1] = React.useState([...[], locationdata.state.primaryimage ? locationdata.state.primaryimage : []])
    const [profImg2, setProfImg2] = React.useState([...[], locationdata.state.primaryimage ? locationdata.state.primaryimage : []])
    const [buttonview, setButtonview] = React.useState(false)
    const [buttonview1, setButtonview1] = React.useState(false)
    const [personName, setPersonName] = React.useState(locationdata.state.Category_id)



    const [categoryDetails, setCategoryDetails] = React.useState([])
    const [servicename, setServiceName] = React.useState(locationdata.state.servicename)
    const [price, setPrice] = React.useState(locationdata.state.pricing)
    const [duration, setDuration] = React.useState(locationdata.state.duration)
    const [location, setLocation] = React.useState(locationdata.state.location)
    const [error, setError] = React.useState({ name: "", username: "", service: "", talent: "" })
    const [viewpage, setViewpage] = React.useState(1)

    const [video1, setVideo1] = React.useState([])
    const [video2, setVideo2] = React.useState([])
    const [addImg1, setAddImg1] = React.useState([])
    const [addImg2, setAddImg2] = React.useState([])

    const [talent, setTalent] = React.useState(locationdata.state.talent_id ? locationdata.state.talent_id._id : "");
    console.log(talent,"talent name ");
    const [talentDetails, setTalentDetails] = React.useState([]);
    const [richeditor, setRichtor] = React.useState(locationdata.state.Description)
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
    const dataofeditor = `${richeditor}`
    const [selectedFromDateTime, setSelectedFromDateTime] = React.useState(dayjs(new Date()));
    const [selectedToDateTime, setSelectedToDateTime] = React.useState(dayjs(new Date()));
    const formatPrice = (price) => {
        // Remove any existing commas and convert to string
        let priceStr = price.toString().replace(/,/g, '');

        // Insert commas every three digits from the end of the string
        priceStr = priceStr.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return priceStr;
    }
    React.useEffect(() => {

        if (dataofeditor) {
            try {
                const contentState = stateFromHTML(dataofeditor);
                setEditorState(EditorState.createWithContent(contentState));
            } catch (error) {
                console.error("Error during HTML to ContentState conversion:", error);
            }
        }
    }, [dataofeditor]);


    React.useEffect(() => {
        convertContentToHTML();
    }, [editorState]);

    const [convertedContent, setConvertedContent] = React.useState(null);


    const handleEditorChange = (newEditorState) => {
        setEditorState(newEditorState);
    }

    const convertContentToHTML = () => {
        const currentContent = editorState.getCurrentContent();
        const currentContentAsHTML = stateToHTML(currentContent);
        setConvertedContent(currentContentAsHTML)
    }




    React.useEffect(() => {
        getCategory()
        getTalentDetails()
    }, [])

    console.log(locationdata.state.talent_id,'locationdata.state.talent_id')


    const getTalentDetails = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `services/dropdownforall`)
            .then((res) => {
                console.log(res.data, "get all details");
                setTalentDetails(res.data.existingArray)
            })
            .catch((err) => {
                console.log(err, "error in get talent");
            })
    }

    const getCategory = async () => {
        await axios.get(process.env.REACT_APP_BASE_URL + `admincategory/getsignupcat`)
            .then((res) => {
                console.log(res.data, "get category");
                setCategoryDetails(res.data.data)
            })
            .catch((error) => {
                console.log(error, "error in get category");
            })
    }

    const profileImageChange = (e, index) => {
        setImageproError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setProfImg1(prevValue => prevValue.concat(fileArray))
        setProfImg2(prevValue => prevValue.concat(filepath))
    }

    const DeleteProf = (e, index) => {
        var filteredpreview = profImg1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = profImg2.filter((val, i) => {
            return i !== index;
        });
        setProfImg1(filteredpreview);
        setProfImg2(filteredraw);
    }



    const multipleImgChange = (e, index) => {
        setImageAddError('')
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setState1(prevValue => prevValue.concat(fileArray))
        setState2(prevValue => prevValue.concat(filepath))
    }

    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
        setExistimg(filteredraw);
    }


    //additionsl images

    const AdditionalImgChange = (e) => {
        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setAddImg1(prevValue => prevValue.concat(fileArray))
        setAddImg2(prevValue => prevValue.concat(filepath))
    }


    const additionalDelete = (e, index) => {
        var filteredpreview = addImg1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = addImg2.filter((val, i) => {
            return i !== index;
        });
        setAddImg1(filteredpreview);
        setAddImg2(filteredraw);
    }
    const Validation = () => {
        if (!servicename && personName?.length == 0 && !talent) {
            setError({ name: "This field is required.", service: "This field is required.", talent: "This field is required." })
            return false
        }
        if (!servicename) {
            setError({ name: "This field is required." })
            return false
        }


        if (!talent) {
            setError({ talent: "This field is required." })
            return false
        }
        if (personName?.length == 0) {
            setError({ service: "This field is required." })
            return false
        }
        return true
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
    }

    const handleFromDateTimeChange = (newValue) => {
        console.log(newValue);
        setSelectedFromDateTime(newValue);
        setError(prevError => ({ ...prevError, fromTime: '' }))
    };
    const handleToDateTimeChange = (newValue) => {
        setSelectedToDateTime(newValue);
        setError(prevError => ({ ...prevError, toTime: '' }))
    };
    const handleBack = () => {
        navigate('/service')
    }
    const UpdateService = async () => {
        let valid = Validation()
        if (valid) {
            // const combinedImages = [...existimg, ...state2];
            // const uniqueImages = [...new Set(combinedImages)];/
            // console.log(uniqueImages, "uniqueImagesuniqueImages");
            let categoryid = personName.map((data) => data._id)
            setButtonview(true)
            // const formData = new FormData();
            // formData.append("servicename", servicename)
            // formData.append("pricing", price)
            // formData.append("isService", true)
            // formData.append("Category_id", categoryid)
            // formData.append("Talent_id", talent)
            // formData.append("admin_id", admindetails._id)
            // formData.append("duration", duration)
            // formData.append("location", location)
            // formData.append("fromdate", selectedFromDateTime)
            // formData.append("todate", selectedToDateTime)
            // formData.append("Description", convertedContent)
            // { profImg2?.length > 0 ? profImg2.forEach((item) => { formData.append("primaryimage", item) }) : formData.append("primaryimage", '') }
            // { state2?.length > 0 ? state2.forEach((item) => { formData.append("additionalimages", item) }) : formData.append("additionalimages", '') }
            // console.log([...formData], "4444");
            let body={
                pricing: price,
                isService: true,
                Category_id: categoryid,
                Talent_id:talent,
                admin_id:admindetails._id,
                duration:duration,
                location:location,
                fromdate:selectedFromDateTime,
                todate:selectedToDateTime,
                Description:convertedContent
            }
            await axios.put(process.env.REACT_APP_BASE_URL + `services/updateservice/${locationdata.state._id}`, body)
                .then((res) => {
                    console.log(res, "res");
                    setButtonview(false)
                    navigate('/service')
                    enqueueSnackbar("Service updated Successfully", { variant: 'success' })
                })
                .catch((error) => {
                    setButtonview(false)
                    console.log(error, "eeee");
                    enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })

                })
        }

    }
    const SubmitContinue = () => {
        let valid = Validation()
        if (valid) {
            setViewpage(2)
        }
    }
    const SubmitBack = () => {
        setViewpage(1)
    }

    const AdditionalVideoChange = (e, index) => {

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files)
        for (let i = 0; i <= fileObj[0].length; i++) {
            if (i < fileObj[0].length) {
                fileArray.push(URL.createObjectURL(fileObj[0][i]))
                filepath.push(fileObj[0][i])
            }
        }
        setVideo1(prevValue => prevValue.concat(fileArray))
        setVideo2(prevValue => prevValue.concat(filepath))
    }

    const indexVideoDelete = (e, index) => {
        var filteredpreview = video1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = video2.filter((val, i) => {
            return i !== index;
        });
        setVideo1(filteredpreview);
        setVideo2(filteredraw);
    }




    const handleMultipleSelect = (event, item) => {
        const { target: { value }, } = event;
        const idCounts = value.reduce((acc, item) => {
            acc[item._id] = (acc[item._id] || 0) + 1;
            return acc;
        }, {});

        const filteredData = value.filter(item => idCounts[item._id] === 1);
        setPersonName(filteredData);
    }


    const [fileTypes, setFileTypes] = React.useState([]);

    React.useEffect(() => {
        const fetchFileTypes = async () => {
            const types = await Promise.all(state1.map(async fileUrl => {
                const type = await getFileType(fileUrl);
                return type;
            }));
            setFileTypes(types);
        };

        fetchFileTypes();
    }, [state1]);



    const getFileType = async (fileUrl) => {
        if (fileUrl.startsWith('blob:')) {
            try {
                const response = await axios.get(fileUrl, { responseType: 'blob' });
                return response.data.type;
            } catch (error) {
                console.error('Error fetching the file:', error);
                return 'video/mp4'
            }
        } else {
            let abc = inferMimeType(fileUrl);
            console.log(abc, "abcabcabc");
            return abc
        }
    };

    const inferMimeType = (fileUrl) => {
        const extension = fileUrl.split('.').pop();
        switch (extension) {

            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
                return `image/${extension}`;
            case 'mp4':
            case 'webm':
            case 'ogg':
                return `video/${extension}`;
            default:
                return null;
        }
    };



    return (
        <Box className='Addcompany'>
            <MiniDrawer type={4} />
            <Box component="section" className="contentWraper">

                <Box className=' whiteContainer'>
                    <Box className='content p-20'>
                        <Grid container rowSpacing={5} columnSpacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={6}>
                                <Typography component='h6' className='formTitle'>Edit Service</Typography>

                                {viewpage == 1 ?
                                    <>
                                        <Grid container rowSpacing={2} columnSpacing={2}>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Title <Typography component='span' className='star'>*</Typography></Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        value={servicename}
                                                        onChange={(e) => { setServiceName(e.target.value); setError({ name: "" }) }}
                                                        placeholder='Title'
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                                {error.name && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.name}</p>}

                                            </Grid>



                                            {/* <Grid item xs={12} sm={12} md={12}  >
                                                <Typography component='div' className='label'>Primary Image <Typography component='span' className='star'> *</Typography></Typography>
                                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                    {profImg1.length < 1 &&
                                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                            <input accept={"image/png, image/jpg,image/jpeg"} onChange={profileImageChange} hidden type="file" />
                                                        </Button>
                                                    }
                                                </Box>
                                            </Grid> */}



{/* 
                                            <Grid item xs={12} sm={12} md={12}  >
                                                {profImg1.length > 0 &&
                                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                        {profImg1.length > 0 ? profImg1.map((x, i) => (
                                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                                <Box className='uploaded_img' >
                                                                    <Button className='close_icon' onClick={(e) => DeleteProf(e, i)}><Close /></Button>

                                                                    <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
                                                                </Box>
                                                            </Grid>
                                                        )) : ""}

                                                    </Grid>
                                                }
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
                                            </Grid> */}

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'>
                                                        Talent<Typography component='span' className='star'>*</Typography>
                                                    </Typography>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        id="demo-select-small"
                                                        value={talent}
                                                        onChange={(e) => {
                                                            console.log(e.target.value,"console")
                                                            setTalent(e.target.value);
                                                            setError({ talent: "" });
                                                        }}
                                                        variant='outlined'
                                                        className='select'
                                                    >
                                                        {talentDetails && talentDetails.length > 0 ? (
                                                            talentDetails.map((data) => (
                                                                <MenuItem key={data._id} value={data._id} clAdditional FilesassName='menuitems'>
                                                                    {data.name}
                                                                </MenuItem>
                                                            ))
                                                        ) : (
                                                            <MenuItem value="" disabled>
                                                                No talent available
                                                            </MenuItem>
                                                        )}
                                                    </Select>
                                                </FormControl>
                                                {error.talent && <p style={{ color: 'red' }} className="fz-13 m-0">{error.talent}</p>}
                                            </Grid>



                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'>Category <Typography component='span' className='star'>*</Typography></Typography>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        id="demo-select-small"
                                                        value={personName}
                                                        multiple
                                                        onChange={handleMultipleSelect}
                                                        input={<OutlinedInput />}
                                                        renderValue={(selected) => {
                                                            if (personName.length === 0) {
                                                                return "No Category";
                                                            } else
                                                                if (personName.length === 1) {
                                                                    return personName[0].category_name;
                                                                } else {
                                                                    return `${personName.length} selected category`;
                                                                }
                                                        }}
                                                        MenuProps={MenuProps}
                                                        variant='outlined'
                                                        className='select'
                                                    >
                                                        {categoryDetails && categoryDetails.length > 0 ? categoryDetails.map((name) => (
                                                            <MenuItem key={name._id} value={name} >
                                                                <Checkbox checked={personName.some(data => data._id == name._id)} />
                                                                <ListItemText primary={name.category_name} />
                                                            </MenuItem>
                                                        )) : <MenuItem>No user found</MenuItem>}
                                                    </Select>
                                                </FormControl>
                                                {error.service && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.service}</p>}

                                            </Grid>
                                            <Grid item xs={8} sm={8} md={8} lg={8}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Price </Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        placeholder='Price'
                                                        value={formatPrice(price)}
                                                        onChange={(e) => setPrice(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                            endAdornment: <InputAdornment position="end">$</InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={4} sm={4} md={4} lg={4}>
                                                <FormControl variant="standard" fullWidth size="small">
                                                    <Typography component='div' className='label'>Duration</Typography>
                                                    <Select
                                                        labelId="demo-select-small"
                                                        id="demo-select-small"
                                                        value={duration}
                                                        onChange={(e) => setDuration(e.target.value)}
                                                        variant='outlined'
                                                        className='select'
                                                    >
                                                        <MenuItem value={'perhour'} className='menuitems'>per-hour</MenuItem>
                                                        <MenuItem value={'perservice'} className='menuitems'>per-service</MenuItem>
                                                        <MenuItem value={'perday'} className='menuitems'>per-day</MenuItem>


                                                    </Select>
                                                </FormControl>
                                            </Grid>


                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <FormControl variant="standard" fullWidth>
                                                    <Typography component='div' className='label'>Location </Typography>
                                                    <TextField
                                                        id="filled-size-small"
                                                        variant="outlined"
                                                        size="small"
                                                        className='textfield'
                                                        placeholder='Location'
                                                        value={location}
                                                        onChange={(e) => setLocation(e.target.value)}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start"></InputAdornment>,
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Typography component='div' className='label'>Start Time <Typography component='span' className='star'>*</Typography></Typography>
                                                <FormControl variant="standard" fullWidth className="datepicker">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                        <DemoContainer components={['DateTimePicker', 'DateTimePicker']} >
                                                            <DateTimePicker
                                                                onChange={handleFromDateTimeChange}
                                                                value={selectedFromDateTime && selectedFromDateTime}
                                                                minDate={dayjs()}
                                                                renderInput={(props) => <TextField {...props} />}
                                                                format="DD-MM-YYYY hh:mm a"
                                                                viewRenderers={{
                                                                    hours: renderTimeViewClock,
                                                                    minutes: renderTimeViewClock,
                                                                    seconds: renderTimeViewClock,
                                                                }}
                                                                className="textfield"
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                    {error.fromTime && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.fromTime}</p>}
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Typography component='div' className='label'>To Time<Typography component='span' className='star'>*</Typography></Typography>
                                                <FormControl variant="standard" fullWidth className="datepicker">
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DateTimePicker', 'DateTimePicker']}>
                                                            <DateTimePicker
                                                                size="small"
                                                                className="textfield"
                                                                onChange={handleToDateTimeChange}
                                                                value={selectedToDateTime && selectedToDateTime}
                                                                minDate={dayjs(selectedFromDateTime)}
                                                                renderInput={(props) => <TextField {...props} />}
                                                                format="DD-MM-YYYY hh:mm a"
                                                                viewRenderers={{
                                                                    hours: renderTimeViewClock,
                                                                    minutes: renderTimeViewClock,
                                                                    seconds: renderTimeViewClock,
                                                                }}
                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                    {error.toTime && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.toTime}</p>}
                                                </FormControl>
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
                                                <Typography component='div' className='label'>Description </Typography>
                                                <div className="editorclass">
                                                    <Editor
                                                        editorState={editorState}
                                                        onEditorStateChange={handleEditorChange}
                                                        toolbarClassName="toolbar"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorcontent"

                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image'],
                                                            inline: {
                                                                options: ['bold', 'italic', 'underline'],
                                                            },
                                                            blockType: {
                                                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
                                                            },
                                                            list: {
                                                                options: ['unordered', 'ordered'],
                                                            },
                                                            textAlign: {
                                                                options: ['left', 'center', 'right', 'justify'],
                                                            },
                                                            link: {
                                                                options: ['link'],
                                                            },
                                                            emoji: {
                                                                options: [
                                                                    '😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
                                                                    '😍', '😋', '😎', '😴', '🤔', '😳', '🤗', '🤐', '😷', '🤒'
                                                                ],
                                                            },
                                                            image: {
                                                                uploadEnabled: true,
                                                                previewImage: true,
                                                                inputAccept: 'image/*',
                                                                alt: { present: false, mandatory: false },
                                                            },
                                                        }}

                                                    />


                                                </div>
                                            </Grid>


                                            {/* <Grid item xs={12} sm={12} md={12}  >
                                            <Typography component='div' className='label'>Additional Files </Typography>
                                                <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                                                    {state1.length < 1 &&

                                                        <Button className="multiple_upload_btn" variant="contained" component="label">
                                                            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
                                                            <input accept={"image/png, image/jpg,image/jpeg,video/mp4"} onChange={multipleImgChange} hidden multiple type="file" />
                                                        </Button>
                                                    }
                                                </Box>
                                            </Grid>
                                            {imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageAddError}</p>}

                                            <Grid item xs={12} sm={12} md={12}  >
                                                {state1.length > 0 &&
                                                    <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
                                                        {state1.length > 0 ? state1.map((x, i) => (
                                                            <Grid item xs={6} sm={4} md={4} lg={4} >
                                                                <Box className='uploaded_img' >
                                                                    {fileTypes[i]?.startsWith('image/') && (
                                                                        <>
                                                                            <img type={fileTypes[i]} src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt="Loaded content" height={155} width={195} />
                                                                        </>
                                                                    )}
                                                                    {fileTypes[i]?.startsWith('video/') && (
                                                                        <>
                                                                            <video type={fileTypes[i]} controls src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt="Loaded content" height={155} width={195} />
                                                                        </>
                                                                    )}
                                                                    <Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
                                                                </Box>
                                                            </Grid>
                                                        )) : ""}
                                                        <Grid item xs={6} sm={4} md={4} lg={4} >
                                                            <label htmlFor="contained-button-file1" className="w-full">
                                                                <Box className='multiple_upload_btn' >
                                                                    <Input
                                                                        inputProps={{ accept: "image/png, image/jpg,image/jpeg,video/mp4" }}
                                                                        onChange={multipleImgChange}
                                                                        style={{ display: "none", }}
                                                                        id="contained-button-file1"
                                                                        type="file"
                                                                    />
                                                                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
                                                                </Box>
                                                            </label>
                                                        </Grid>
                                                    </Grid>
                                                }
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                                <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
                                            </Grid> */}

                                        </Grid>
                                    </>
                                    :

                                    // 22222222222
                                    <>
                                
                                    </>

                                }
                            </Grid>
                        </Grid>

                        {viewpage == 1 ?
                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                {buttonview == false ?
                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                        <Button variant="contained" className='addbtn mr-10' onClick={handleBack}>Back</Button>

                                        <Button onClick={UpdateService} variant="contained" className='addbtn mr-10' >Update</Button>
                                    </Box>
                                    :
                                    <Box style={{ marginTop: 10 }} className='heading-btns'>
                                        <Button variant="contained" className='addbtn mr-10' >Please Wait...</Button>
                                    </Box>

                                }

                            </Box>
                            :

                            ""
                        }
                    </Box>

                </Box>

            </Box>
        </Box>
    )
}


// import { Box, Button, FormControl, Grid, InputAdornment, TextField, Typography, Input,OutlinedInput,Checkbox,ListItemText } from '@mui/material'
// import React from 'react'
// import MiniDrawer from '../../../components/drawer'
// import MenuItem from '@mui/material/MenuItem';
// import Select from '@mui/material/Select';
// import { SearchOutlined } from '@mui/icons-material';
// import { useNavigate ,useLocation} from 'react-router-dom';
// import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
// import { Close, Dvr, ListAlt } from '@mui/icons-material';
// import axios from 'axios'
// import { useSnackbar, SnackbarProvider } from 'notistack'
// import VideocamIcon from '@mui/icons-material/Videocam';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import dayjs from 'dayjs';
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
// import { Editor } from 'react-draft-wysiwyg';
// import { stateToHTML } from 'draft-js-export-html';
// import { EditorState } from 'draft-js';
// import { stateFromHTML } from 'draft-js-import-html';
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
// 	PaperProps: {
// 		style: {
// 			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
// 			width: 250,
// 		},
// 	},
// };
// export default function EditTalent() {
//     let locationdata = useLocation()
//     console.log(locationdata.state,"location");

//     const [value, setValue] = React.useState(dayjs(locationdata.state.Date));

//     let admindetails = JSON.parse(localStorage.getItem("userData"))
//     let navigate = useNavigate()
//     const { enqueueSnackbar } = useSnackbar()
//     const [imageAddError, setImageAddError] = React.useState('')
//     const [imageproError, setImageproError] = React.useState('')
//     const [state1, setState1] = React.useState(locationdata.state.coverimage)
//     const [state2, setState2] = React.useState(locationdata.state.coverimage)

//     const [profImg1, setProfImg1] = React.useState([...[],locationdata.state.primaryimage])
//     const [profImg2, setProfImg2] = React.useState([...[],locationdata.state.primaryimage])
//     const [buttonview, setButtonview] = React.useState(false)
//     const [buttonview1, setButtonview1] = React.useState(false)

//     const [categoryDetails, setCategoryDetails] = React.useState([])
//     const [servicename, setServiceName] = React.useState(locationdata.state.servicename)
//     const [username, setUserName] = React.useState(locationdata.state.username)
//     const [price, setPrice] = React.useState(locationdata.state.pricing)
//     const [category, setCategory] = React.useState(locationdata.state.Category_id._id )
//     const [duration, setDuration] = React.useState(locationdata.state.duration)
//     const [location, setLocation] = React.useState(locationdata.state.location)
//     const [error, setError] = React.useState({ name: "", username: "", service: "" })
//     const [viewpage, setViewpage] = React.useState(1)

//     const [video1, setVideo1] = React.useState(locationdata.state.addionalvideo ? locationdata.state.addionalvideo:[])
//     const [video2, setVideo2] = React.useState(locationdata.state.addionalvideo ? locationdata.state.addionalvideo:[])

//     const [addImg1, setAddImg1] = React.useState(locationdata.state.addionalimage ? locationdata.state.addionalimage:[])
//     const [addImg2, setAddImg2] = React.useState(locationdata.state.addionalimage ? locationdata.state.addionalimage:[])
//     const [personName, setPersonName] = React.useState(locationdata.state.Category_id)
// 	const [richeditor, setRichtor] = React.useState(locationdata.state.Description)
// 	const [editorState, setEditorState] = React.useState(EditorState.createEmpty());
// 	const dataofeditor = `${richeditor}`

// 	React.useEffect(() => {

// 		if (dataofeditor) {
// 			try {
// 				const contentState = stateFromHTML(dataofeditor);
// 				setEditorState(EditorState.createWithContent(contentState));
// 			} catch (error) {
// 				console.error("Error during HTML to ContentState conversion:", error);
// 			}
// 		}
// 	}, [dataofeditor]);

//     const handleMultipleSelect = (event, item) => {
// 		const {target: { value },} = event;
//         const idCounts = value.reduce((acc, item) => {
//             acc[item._id] = (acc[item._id] || 0) + 1;
//             return acc;
//         }, {});

//         const filteredData = value.filter(item => idCounts[item._id] === 1);
// 		setPersonName(filteredData);
// 	}



// 	React.useEffect(() => {
// 		convertContentToHTML();
// 	}, [editorState]);

// 	const [convertedContent, setConvertedContent] = React.useState(null);

// 	const handleEditorChange = (newEditorState) => {
// 		setEditorState(newEditorState);
// 	}

// 	const convertContentToHTML = () => {
// 		const currentContent = editorState.getCurrentContent();
// 		const currentContentAsHTML = stateToHTML(currentContent);
// 		setConvertedContent(currentContentAsHTML)
// 	}




//     React.useEffect(() => {
//         getCategory()
//     }, [])


//     const getCategory = async () => {
//         await axios.get(process.env.REACT_APP_BASE_URL + `admincategory/getallcategories?isdropdown=true`)
//             .then((res) => {
//                 setCategoryDetails(res.data.data)
//             })
//             .catch((error) => {
//                 console.log(error, "error in get category");
//             })
//     }

//     const profileImageChange = (e, index) => {
//         setImageproError('')
//         var fileObj = [];
//         var fileArray = [];
//         var filepath = [];

//         fileObj.push(e.target.files)
//         for (let i = 0; i <= fileObj[0].length; i++) {
//             if (i < fileObj[0].length) {
//                 fileArray.push(URL.createObjectURL(fileObj[0][i]))
//                 filepath.push(fileObj[0][i])
//             }
//         }
//         setProfImg1(prevValue => prevValue.concat(fileArray))
//         setProfImg2(prevValue => prevValue.concat(filepath))
//     }

//     const DeleteProf = (e, index) => {
//         var filteredpreview = profImg1.filter((value, i) => {
//             return i !== index;
//         });
//         var filteredraw = profImg2.filter((val, i) => {
//             return i !== index;
//         });
//         setProfImg1(filteredpreview);
//         setProfImg2(filteredraw);
//     }



//     const multipleImgChange = (e, index) => {
//         setImageAddError('')
//         var fileObj = [];
//         var fileArray = [];
//         var filepath = [];

//         fileObj.push(e.target.files)
//         for (let i = 0; i <= fileObj[0].length; i++) {
//             if (i < fileObj[0].length) {
//                 fileArray.push(URL.createObjectURL(fileObj[0][i]))
//                 filepath.push(fileObj[0][i])
//             }
//         }
//         setState1(prevValue => prevValue.concat(fileArray))
//         setState2(prevValue => prevValue.concat(filepath))
//     }

//     const indexDelete = (e, index) => {
//         var filteredpreview = state1.filter((value, i) => {
//             return i !== index;
//         });
//         var filteredraw = state2.filter((val, i) => {
//             return i !== index;
//         });
//         setState1(filteredpreview);
//         setState2(filteredraw);
//     }


//     //additionsl images

//     const AdditionalImgChange = (e) => {
//         var fileObj = [];
//         var fileArray = [];
//         var filepath = [];

//         fileObj.push(e.target.files)
//         for (let i = 0; i <= fileObj[0].length; i++) {
//             if (i < fileObj[0].length) {
//                 fileArray.push(URL.createObjectURL(fileObj[0][i]))
//                 filepath.push(fileObj[0][i])
//             }
//         }
//         setAddImg1(prevValue => prevValue.concat(fileArray))
//         setAddImg2(prevValue => prevValue.concat(filepath))
//     }


//     const additionalDelete = (e, index) => {
//         var filteredpreview = addImg1.filter((value, i) => {
//             return i !== index;
//         });
//         var filteredraw = addImg2.filter((val, i) => {
//             return i !== index;
//         });
//         setAddImg1(filteredpreview);
//         setAddImg2(filteredraw);
//     }




//     const Validation = () => {
//         if (!servicename && !username && profImg2?.length == 0 && personName?.length == 0) {
//             setError({ name: "This field is required.", username: "This field is required.", service: "This field is required." })
//             return false
//         }
//         if (!servicename) {
//             setError({ name: "This field is required." })
//             return false
//         }
//         if (!username) {
//             setError({ username: "This field is required." })
//             return false
//         }
//         if (profImg2?.length == 0) {
//             setImageproError("This field is required.")
//             return false
//         }
//         if (personName?.length == 0) {
//             setError({ service: "This field is required." })
//             return false
//         }


//         return true
//     }
//     const headerformdata = {
//         'Content-Type': 'multipart/form-data',
//     }

//     const CreateService = async () => {
//         let valid = Validation()
//         if (valid) {
//             setButtonview(true)
//             let categoryid = personName.map((data) => data._id)
//             const formData = new FormData();
//             formData.append("servicename", servicename)
//             formData.append("username", username)
//             formData.append("pricing", price)
//             formData.append("isAdmin", true)
//             formData.append("isStatus", "Approve")
//             formData.append("isService", true)
//             // formData.append("Category_id", category)
//             formData.append("Category_id", categoryid)


//             formData.append("admin_id", admindetails._id)
//             formData.append("duration", duration)
//             formData.append("location", location)
//             formData.append("Date", value)
//             formData.append("Description", convertedContent)
//             { profImg2?.length > 0 ? profImg2.forEach((item) => { formData.append("primaryimage", item) }) : formData.append("primaryimage", '') }
//             { state2?.length > 0 ? state2.forEach((item) => { formData.append("coverimage", item) }) : formData.append("coverimage", '') }
//             console.log([...formData], "4444");
//             await axios.put(process.env.REACT_APP_BASE_URL + `services/updateservice/${locationdata.state._id}`, formData, { headers: headerformdata })
//                 .then((res) => {
//                     console.log(res, "res");
//                     setButtonview(false)
//                     navigate('/service')
//                     enqueueSnackbar("Added Successfully", { variant: 'success' })
//                 })
//                 .catch((error) => {
//                     setButtonview(false)
//                     console.log(error, "eeee");
//                     enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })

//                 })
//         }

//     }


//     const SubmitContinue = () => {
//         let valid = Validation()
//         if (valid) {
//             setViewpage(2)
//         }
//     }
//     const SubmitBack = () => {
//         setViewpage(1)
//     }





//     const AdditionalVideoChange = (e, index) => {

//         var fileObj = [];
//         var fileArray = [];
//         var filepath = [];

//         fileObj.push(e.target.files)
//         for (let i = 0; i <= fileObj[0].length; i++) {
//             if (i < fileObj[0].length) {
//                 fileArray.push(URL.createObjectURL(fileObj[0][i]))
//                 filepath.push(fileObj[0][i])
//             }
//         }
//         setVideo1(prevValue => prevValue.concat(fileArray))
//         setVideo2(prevValue => prevValue.concat(filepath))
//     }

//     const indexVideoDelete = (e, index) => {
//         var filteredpreview = video1.filter((value, i) => {
//             return i !== index;
//         });
//         var filteredraw = video2.filter((val, i) => {
//             return i !== index;
//         });
//         setVideo1(filteredpreview);
//         setVideo2(filteredraw);
//     }


//     const SubmitContinueNew = async() => {
//         let valid = Validation()
//         if (valid) {
//             setButtonview1(true)
//             let categoryid = personName.map((data) => data._id)
//             const formData = new FormData();
//             formData.append("servicename", servicename)
//             formData.append("username", username)

//             formData.append("pricing", price)
//             formData.append("isAdmin", true)
//             formData.append("isStatus", "Approve")
//             formData.append("isService", true)
//             // formData.append("Category_id", category)
//             formData.append("Category_id", categoryid)

//             formData.append("admin_id", admindetails._id)
//             formData.append("duration", duration)
//             formData.append("location", location)
//             formData.append("Date", value)
//             formData.append("Description", convertedContent)
//             { profImg2?.length > 0 ? profImg2.forEach((item) => { formData.append("primaryimage", item) }) : formData.append("primaryimage", '') }
//             { state2?.length > 0 ? state2.forEach((item) => { formData.append("coverimage", item) }) : formData.append("coverimage", '') }

//             { addImg2?.length > 0 ? addImg2.forEach((item) => { formData.append("addionalimage", item) }) : formData.append("addionalimage", '') }
//             { video2?.length > 0 ? video2.forEach((item) => { formData.append("addionalvideo", item) }) : formData.append("addionalvideo", '') }



//             console.log([...formData], "4444");
//             await axios.put(process.env.REACT_APP_BASE_URL + `services/updateservice/${locationdata.state._id}`, formData, { headers: headerformdata })
//                 .then((res) => {
//                     console.log(res, "res");
//                     setButtonview1(false)

//                     navigate('/service')
//                     enqueueSnackbar("Added Successfully", { variant: 'success' })
//                 })
//                 .catch((error) => {
//                     setButtonview1(false)
//                     console.log(error, "eeee");
//                     enqueueSnackbar(error.message == 'Network Error' ? error.message : error.response.data.message ? error.response.data.message : "Something went wrong.", { variant: 'error' })

//                 })
//         }

//     }

//     return (
//         <Box className='Addcompany'>
//             <MiniDrawer type={2} />
//             <Box component="section" className="contentWraper">

//                 <Box className=' whiteContainer'>
//                     <Box className='content p-20'>
//                         <Grid container rowSpacing={5} columnSpacing={3}>
//                             <Grid item xs={12} sm={12} md={12} lg={6}>
//                                 <Typography component='h6' className='formTitle'>Edit Service</Typography>

//                                 {viewpage == 1 ?
//                                     <>
//                                         <Grid container rowSpacing={2} columnSpacing={2}>
//                                             <Grid item xs={12} sm={12} md={12} lg={12}>
//                                                 <FormControl variant="standard" fullWidth>
//                                                     <Typography component='div' className='label'>Title <Typography component='span' className='star'>*</Typography></Typography>
//                                                     <TextField
//                                                         id="filled-size-small"
//                                                         variant="outlined"
//                                                         size="small"
//                                                         className='textfield'
//                                                         value={servicename}
//                                                         onChange={(e) => { setServiceName(e.target.value); setError({ name: "" }) }}
//                                                         placeholder='Title'
//                                                         InputProps={{
//                                                             startAdornment: <InputAdornment position="start"></InputAdornment>,
//                                                         }}
//                                                     />
//                                                 </FormControl>
//                                                 {error.name && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.name}</p>}

//                                             </Grid>

//                                             <Grid item xs={12} sm={12} md={12} lg={12}>
//                                                 <FormControl variant="standard" fullWidth>
//                                                     <Typography component='div' className='label'>UserName <Typography component='span' className='star'>*</Typography></Typography>
//                                                     <TextField
//                                                         id="filled-size-small"
//                                                         variant="outlined"
//                                                         size="small"
//                                                         disabled
//                                                         className='textfield'
//                                                         value={username}
//                                                         onChange={(e) => { setUserName(e.target.value); setError({ username: "" }) }}
//                                                         placeholder='UserName'
//                                                         InputProps={{
//                                                             startAdornment: <InputAdornment position="start"></InputAdornment>,
//                                                         }}
//                                                     />
//                                                 </FormControl>
//                                                 {error.username && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.username}</p>}

//                                             </Grid>

//                                             <Grid item xs={12} sm={12} md={12}  >
//                                                 <label className='form-labels ' for="my-input" >Primary Image<Typography component='span' className='star'>*</Typography></label>
//                                                 <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
//                                                     {profImg1.length < 1 &&
//                                                         <Button className="multiple_upload_btn" variant="contained" component="label">
//                                                             <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
//                                                             <input accept={"image/png, image/jpg,image/jpeg,video/mp4"} onChange={profileImageChange} hidden type="file" />
//                                                         </Button>
//                                                     }
//                                                 </Box>
//                                             </Grid>

//                                             {imageproError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageproError}</p>}



//                                             <Grid item xs={12} sm={12} md={12}  >
//                                                 {profImg1.length > 0 &&
//                                                     <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
//                                                         {profImg1.length > 0 ? profImg1.map((x, i) => (
//                                                             <Grid item xs={6} sm={4} md={4} lg={4} >
//                                                                 <Box className='uploaded_img' >
//                                                                     <Button className='close_icon' onClick={(e) => DeleteProf(e, i)}><Close /></Button>
//                                                                     <img  src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
//                                                                 </Box>
//                                                             </Grid>
//                                                         )) : ""}

//                                                     </Grid>
//                                                 }
//                                             </Grid>

//                                             <Grid item xs={12} sm={12} md={12} lg={12}>
//                                                 <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
//                                             </Grid>



//                                             <Grid item xs={12} sm={12} md={12} lg={12}>
//                                                 <FormControl variant="standard" fullWidth size="small">
//                                                     <Typography component='div' className='label'>Category Type <Typography component='span' className='star'>*</Typography></Typography>
//                                                     <Select
//                                                         labelId="demo-select-small"
//                                                         id="demo-select-small"
//                                                         value={personName}
//                                                         multiple
//                                                         onChange={handleMultipleSelect}
//                                                         input={<OutlinedInput />}
//                                                         renderValue={(selected) => {

//                                                             const selectedEmployees = categoryDetails.filter(employee => selected.includes(employee.category_name));
//                                                             // if (selectedEmployees.length === 0) {
//                                                             //     return "Any employee";
//                                                             // } else
//                                                             // if (personName.length === 1) {
//                                                             //     return personName[0].category_name;
//                                                             // } else {
//                                                                 return `${personName.length} selected category`;
//                                                             // }
//                                                         }}
//                                                         MenuProps={MenuProps}
//                                                         // onChange={(e) => { setCategory(e.target.value); setError({ service: "" }) }}
//                                                         variant='outlined'
//                                                         className='select'
//                                                     >
//                                                         {categoryDetails && categoryDetails.length > 0 ? categoryDetails.map((name) => (
// 														<MenuItem key={name._id} value={name} >
// 															{/* <Checkbox checked={personName.indexOf(name.category_name) > -1} /> */}
//                                                             <Checkbox checked={personName.some(data => data._id == name._id)} />
// 															<ListItemText primary={name.category_name} />
// 														</MenuItem>
// 													)) : <MenuItem>No user found</MenuItem>}
//                                                         {/* {categoryDetails && categoryDetails?.length > 0 && categoryDetails.map((data) => (
//                                                             <MenuItem value={data._id} className='menuitems'>{data.category_name}</MenuItem>
//                                                         ))} */}

//                                                     </Select>
//                                                 </FormControl>
//                                                 {error.service && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.service}</p>}

//                                             </Grid>


//                                             {/* <Grid item xs={12} sm={12} md={12} lg={12}>
//                                                 <FormControl variant="standard" fullWidth size="small">
//                                                     <Typography component='div' className='label'>Category Type <Typography component='span' className='star'>*</Typography></Typography>
//                                                     <Select
//                                                         labelId="demo-select-small"
//                                                         id="demo-select-small"
//                                                         value={category}
//                                                         onChange={(e) => { setCategory(e.target.value); setError({ service: "" }) }}
//                                                         variant='outlined'
//                                                         className='select'
//                                                     >
//                                                         {categoryDetails && categoryDetails?.length > 0 && categoryDetails.map((data) => (
//                                                             <MenuItem value={data._id} className='menuitems'>{data.category_name}</MenuItem>
//                                                         ))}

//                                                     </Select>
//                                                 </FormControl>
//                                                 {error.service && <p style={{ color: 'red' }} className="fz-13 m-0" >{error.service}</p>}

//                                             </Grid> */}

//                                             <Grid item xs={12} sm={12} md={12} lg={12}>
//                                                 <FormControl variant="standard" fullWidth size="small">
//                                                     <Typography component='div' className='label'>Duration</Typography>
//                                                     <Select
//                                                         labelId="demo-select-small"
//                                                         id="demo-select-small"
//                                                         value={duration}
//                                                         onChange={(e) => setDuration(e.target.value)}
//                                                         variant='outlined'
//                                                         className='select'
//                                                     >
//                                                         <MenuItem value={'perhour'} className='menuitems'>per-hour</MenuItem>
//                                                         <MenuItem value={'perservice'} className='menuitems'>per-service</MenuItem>
//                                                         <MenuItem value={'perday'} className='menuitems'>per-day</MenuItem>


//                                                     </Select>
//                                                 </FormControl>
//                                             </Grid>

//                                             <Grid item xs={12} sm={12} md={12} lg={12}>
//                                                 <FormControl variant="standard" fullWidth>
//                                                     <Typography component='div' className='label'>Price </Typography>
//                                                     <TextField
//                                                         id="filled-size-small"
//                                                         variant="outlined"
//                                                         size="small"
//                                                         className='textfield'
//                                                         placeholder='Price'
//                                                         value={price}
//                                                         onChange={(e) => setPrice(e.target.value)}
//                                                         InputProps={{
//                                                             startAdornment: <InputAdornment position="start"></InputAdornment>,
//                                                         }}
//                                                     />
//                                                 </FormControl>
//                                             </Grid>

//                                             <Grid item xs={12} sm={12} md={12} lg={12}>
//                                                 <FormControl variant="standard" fullWidth>
//                                                     <Typography component='div' className='label'>Location </Typography>
//                                                     <TextField
//                                                         id="filled-size-small"
//                                                         variant="outlined"
//                                                         size="small"
//                                                         className='textfield'
//                                                         placeholder='Location'
//                                                         value={location}
//                                                         onChange={(e) => setLocation(e.target.value)}
//                                                         InputProps={{
//                                                             startAdornment: <InputAdornment position="start"></InputAdornment>,
//                                                         }}
//                                                     />
//                                                 </FormControl>
//                                             </Grid>

//                                             <Grid item xs={12} sm={12} md={12} lg={12}>
//                                                 <FormControl variant="standard" fullWidth>
//                                                     <Typography component='div' className='label'>Date </Typography>
//                                                     <LocalizationProvider dateAdapter={AdapterDayjs}>
//                                                         <DemoContainer components={['DatePicker', 'DatePicker']}>

//                                                             <DatePicker
//                                                                 label="Date"
//                                                                 value={value}
//                                                                 onChange={(newValue) => setValue(newValue)}
//                                                             />
//                                                         </DemoContainer>
//                                                     </LocalizationProvider>
//                                                 </FormControl>
//                                             </Grid>

//                                             <Grid item xs={12} sm={12} md={12} lg={12} className='mb-10'>
// 									<Typography component='div' className='label'>Description </Typography>
// 									<div className="editorclass">
// 										<Editor
// 											editorState={editorState}
// 											onEditorStateChange={handleEditorChange}
// 											toolbarClassName="toolbar"
// 											wrapperClassName="wrapperClassName"
// 											editorClassName="editorcontent"

// 											toolbar={{
// 												options: ['inline', 'blockType', 'list', 'textAlign', 'link', 'emoji', 'image'],
// 												inline: {
// 													options: ['bold', 'italic', 'underline'],
// 												},
// 												blockType: {
// 													options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
// 												},
// 												list: {
// 													options: ['unordered', 'ordered'],
// 												},
// 												textAlign: {
// 													options: ['left', 'center', 'right', 'justify'],
// 												},
// 												link: {
// 													options: ['link'],
// 												},
// 												emoji: {
// 													options: [
// 														'😀', '😃', '😄', '😁', '😆', '😅', '😂', '🤣', '😊', '😇',
// 														'😍', '😋', '😎', '😴', '🤔', '😳', '🤗', '🤐', '😷', '🤒'
// 													],
// 												},
// 												image: {
// 													uploadEnabled: true,
// 													previewImage: true,
// 													inputAccept: 'image/*',
// 													alt: { present: false, mandatory: false },
// 												},
// 											}}

// 										/>


// 									</div>
// 								</Grid>

//                                             <Grid item xs={12} sm={12} md={12}  >
//                                                 <label className='form-labels ' for="my-input" >Cover Image</label>
//                                                 <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
//                                                     {state1.length < 1 &&

//                                                         <Button className="multiple_upload_btn" variant="contained" component="label">
//                                                             <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
//                                                             <input accept={"image/png, image/jpg,image/jpeg,video/mp4"} onChange={multipleImgChange} hidden multiple type="file" />
//                                                         </Button>
//                                                     }
//                                                 </Box>
//                                             </Grid>
//                                             {imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageAddError}</p>}

//                                             <Grid item xs={12} sm={12} md={12}  >
//                                                 {state1.length > 0 &&
//                                                     <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
//                                                         {state1.length > 0 ? state1.map((x, i) => (
//                                                             <Grid item xs={6} sm={4} md={4} lg={4} >
//                                                                 <Box className='uploaded_img' >
//                                                                     <Button className='close_icon' onClick={(e) => indexDelete(e, i)}><Close /></Button>
//                                                                     <img  src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`}alt='' height={155} width={195} />
//                                                                 </Box>
//                                                             </Grid>
//                                                         )) : ""}
//                                                         <Grid item xs={6} sm={4} md={4} lg={4} >
//                                                             <label htmlFor="contained-button-file1" className="w-full">
//                                                                 <Box className='multiple_upload_btn' >
//                                                                     <Input
//                                                                         inputProps={{ accept: "image/png, image/jpg,image/jpeg,video/mp4" }}
//                                                                         onChange={multipleImgChange}
//                                                                         style={{ display: "none", }}
//                                                                         id="contained-button-file1"
//                                                                         type="file"
//                                                                     />
//                                                                     <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
//                                                                 </Box>
//                                                             </label>
//                                                         </Grid>
//                                                     </Grid>
//                                                 }
//                                             </Grid>
//                                             <Grid item xs={12} sm={12} md={12} lg={12}>
//                                                 <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
//                                             </Grid>

//                                         </Grid>
//                                     </>
//                                     :

//                                     // 22222222222
//                                     <>
//                                       <Grid item xs={12} sm={12} md={12}  >
//                                             {/* <Typography component='div' className='label'>servicename {servicename ? servicename : "-"}</Typography> */}
//                                             <Box className='mb-10 d-flex align-items-start justify-content-between'>
//                                                             <Box className=''>
//                                                                 <Typography component='grid' variant='body1' className='fz-14 semibold'>Servicename: </Typography>
//                                                                 <Typography component='grid' variant='body1' className='fz-14 '>{servicename ? servicename : "-"}</Typography>
//                                                             </Box>
//                                                             <Box className=''>
//                                                                 <Typography component='grid' variant='body1' className='fz-14 semibold'>Username: </Typography>
//                                                                 <Typography component='grid' variant='body1' className='fz-14 '>{username ? username : "-"}</Typography>
//                                                             </Box>

//                                                             <Box className=''>
//                                                                 <Typography component='grid' variant='body1' className='fz-14 semibold'>Location: </Typography>
//                                                                 <Typography component='grid' variant='body1' className='fz-14 '>{location ? location : "-"}</Typography>
//                                                             </Box>

//                                                             <Box className=''>
//                                                                 <Typography component='grid' variant='body1' className='fz-14 semibold'>Duration: </Typography>
//                                                                 <Typography component='grid' variant='body1' className='fz-14 '>{duration ? duration : "-"}</Typography>
//                                                             </Box>
//                                                             <Box className=''>
//                                                                 <Typography component='grid' variant='body1' className='fz-14 semibold'>Price: </Typography>
//                                                                 <Typography component='grid' variant='body1' className='fz-14 '>{price ? price : "-"}</Typography>
//                                                             </Box>
//                                                         </Box>

//                                         <Typography component='grid' variant='body1' className='fz-14 semibold'>Primary Image: </Typography>
//                                             {profImg1.length > 0 ? profImg1.map((x, i) => (
//                                                 <Grid item xs={6} sm={4} md={4} lg={4} >
//                                                     <Box className='uploaded_img' >
//                                                         <img  src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
//                                                     </Box>
//                                                 </Grid>
//                                             )) : ""}

//                                             {/* <Typography component='div' className='label'>Date {value ? value : "-"} </Typography> */}
//                                             <Typography component='grid' variant='body1' className='fz-14 semibold'>Cover Image: </Typography>

//                                             {state1.length > 0 ? state1.map((x, i) => (
//                                                 <Grid item xs={6} sm={4} md={4} lg={4} >
//                                                     <Box className='uploaded_img' >
//                                                         <img  src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
//                                                     </Box>
//                                                 </Grid>
//                                             )) : ""}
//                                             </Grid>

//                                         <Grid item xs={12} sm={12} md={12}  >
//                                             <label  className='form-labels' for="my-input" >Additional Images</label>
//                                             <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
//                                                 {addImg1.length < 1 &&
//                                                     <Button className="multiple_upload_btn" variant="contained" component="label">
//                                                         <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon className="fz-18 mr-5" /> Upload</Box>
//                                                         <input accept={"image/png, image/jpg,image/jpeg"} onChange={AdditionalImgChange} hidden multiple type="file" />
//                                                     </Button>
//                                                 }
//                                             </Box>

//                                         {imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageAddError}</p>}

//                                         <Grid item xs={12} sm={12} md={12}  >
//                                             {addImg1.length > 0 &&
//                                                 <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
//                                                     {addImg1.length > 0 ? addImg1.map((x, i) => (
//                                                         <Grid item xs={6} sm={4} md={4} lg={4} >
//                                                             <Box className='uploaded_img' >
//                                                                 <Button className='close_icon' onClick={(e) => additionalDelete(e, i)}><Close /></Button>
//                                                                 <img src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
//                                                             </Box>
//                                                         </Grid>
//                                                     )) : ""}
//                                                     <Grid item xs={6} sm={4} md={4} lg={4} >
//                                                         <label htmlFor="contained-button-file1" className="w-full">
//                                                             <Box className='multiple_upload_btn' >
//                                                                 <Input
//                                                                     inputProps={{ accept: "image/png, image/jpg,image/jpeg" }}
//                                                                     onChange={AdditionalImgChange}
//                                                                     style={{ display: "none", }}
//                                                                     id="contained-button-file1"
//                                                                     type="file"
//                                                                 />
//                                                                 <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><FileUploadOutlinedIcon /></Box>
//                                                             </Box>
//                                                         </label>
//                                                     </Grid>
//                                                 </Grid>
//                                             }
//                                         </Grid>
//                                         <Grid item xs={12} sm={12} md={12} lg={12}>
//                                             <Typography className=' fz-12 mt-10 mb-10' component='p' variant='p'><strong>Note:</strong> Image size should be more than 1920px * 1000px for better quality picture.</Typography>
//                                         </Grid>


//                                         <Grid item xs={12} sm={12} md={12}  >
//                                             <label className='form-labels ' for="my-input" >Additional Videos</label>
//                                             <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
//                                                 {video1?.length < 1 &&
//                                                     <Button className="multiple_upload_btn" variant="contained" component="label">
//                                                         <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><VideocamIcon className="fz-18 mr-5" /> Upload</Box>
//                                                         <input accept={"video/mp4"} onChange={AdditionalVideoChange} hidden multiple type="file" />
//                                                     </Button>
//                                                 }
//                                             </Box>
//                                         </Grid>
//                                         {/* {imageAddError && <p style={{ color: 'red' }} className="fz-13 m-0" >{imageAddError}</p>} */}

//                                         <Grid item xs={12} sm={12} md={12}  >
//                                             {video1?.length > 0 &&
//                                                 <Grid container columnSpacing={1} rowSpacing={1} className="multiple_upload_container mb-10">
//                                                     {video1?.length > 0 ? video1.map((x, i) => (
//                                                         <Grid item xs={6} sm={4} md={4} lg={4} >
//                                                             <Box className='uploaded_img' >
//                                                                 <Button className='close_icon' onClick={(e) => indexVideoDelete(e, i)}><Close /></Button>
//                                                                 <video  src={x.slice(0, 4) === "blob" ? x : process.env.REACT_APP_BASE_URL + `${x}`} alt='' height={155} width={195} />
//                                                             </Box>
//                                                         </Grid>
//                                                     )) : ""}
//                                                     <Grid item xs={6} sm={4} md={4} lg={4} >
//                                                         <label htmlFor="contained-button-file1" className="w-full">
//                                                             <Box className='multiple_upload_btn' >
//                                                                 <Input
//                                                                     inputProps={{ accept: "video/mp4" }}
//                                                                     onChange={AdditionalVideoChange}
//                                                                     style={{ display: "none", }}
//                                                                     id="contained-button-file1"
//                                                                     type="file"
//                                                                 />
//                                                                 <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><VideocamIcon /></Box>
//                                                             </Box>
//                                                         </label>
//                                                     </Grid>
//                                                 </Grid>
//                                             }
//                                         </Grid>


//                                         </Grid>
//                                     </>

//                                 }
//                             </Grid>
//                         </Grid>

//                         {viewpage == 1 ?
//                             <Box style={{ display: 'flex', alignItems: 'center' }}>
//                                 {buttonview == false ?
//                                     <Box style={{ marginTop: 10 }} className='heading-btns'>
//                                         <Button onClick={CreateService} variant="contained" className='addbtn mr-10' >Update</Button>
//                                     </Box>
//                                     :
//                                     <Box style={{ marginTop: 10 }} className='heading-btns'>
//                                         <Button variant="contained" className='addbtn mr-10' >Please Wait...</Button>
//                                     </Box>

//                                 }

//                                 <Box style={{ marginTop: 10 }} className='heading-btns'>
//                                     <Button onClick={SubmitContinue} variant="contained" className='addbtn mr-10' >Update and Continue</Button>
//                                 </Box>
//                             </Box>
//                             :

//                             <Box style={{ display: 'flex', alignItems: 'center' }}>
//                                 <Box style={{ marginTop: 10 }} className='heading-btns'>
//                                     <Button onClick={SubmitBack} variant="contained" className='addbtn mr-10' >Back</Button>
//                                 </Box>

//                                 {buttonview1 == false ?
//                                 <Box style={{ marginTop: 10 }} className='heading-btns'>
//                                     <Button onClick={SubmitContinueNew} variant="contained" className='addbtn mr-10' >Update</Button>
//                                 </Box>
//                                 :
//                                 <Box style={{ marginTop: 10 }} className='heading-btns'>
//                                 <Button  variant="contained" className='addbtn mr-10' >Please Wait...</Button>
//                             </Box>}
//                             </Box>
//                         }
//                     </Box>

//                 </Box>

//             </Box>
//         </Box>
//     )
// }
